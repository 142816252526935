import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Card, Col, Button, ButtonToolbar, Table, CardBody,
} from 'reactstrap';
import Alert from '../../../shared/components/Alert';
import Modal from '../../../shared/components/Modal';
import { NotificationManager } from 'react-notifications';
import { singleOrder, orderProcess, cancelOrder } from '../../../redux/actions/orderActions'


const InvoiceTemplate = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const singleOrderDetails = useSelector((state) => state.orders.singleData);
  const isFetching = useSelector((state) => state.orders.isFetching);
  const orderStatusError = useSelector((state) => state.users.error);


  const [singleOrderData, setSingleOrderData] = useState([]);
  const [orderId, setOrderId] = useState()
  const [showProcessAlert, setshowProcessAlert] = useState(false);
  const [showCancelAlert, setShowCancelAlert] = useState(false);

  const [confirmProcessClicked, setConfirmProcessClicked] = useState(false);
  const [confirmCancelClicked, setConfirmCancelClicked] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);


  console.log(singleOrderData)

  const selectedOrderId = location.state.orderId

  useEffect(() => {
    setOrderId(selectedOrderId);
    let adminObj = JSON.parse(localStorage.getItem("adminInfo"));
    if (adminObj) {
      dispatch(singleOrder(selectedOrderId));
    } else {
      history.push("/log_in");
    }
  }, [dispatch, history, location.state.orderId]);


  useEffect(() => {
    if (!isFetching) {
      if (confirmProcessClicked) {
        if (orderStatusError) {
          NotificationManager.error(orderStatusError.message, '', 3000);
          setConfirmProcessClicked(false);
        }
        else {
          NotificationManager.success('Order status changed to Processing', '', 3000);
          setConfirmProcessClicked(false);
          dispatch(singleOrder(selectedOrderId));
          // history.push('/orders');
        }
      }
      else if (confirmCancelClicked) {
        if (orderStatusError) {
          NotificationManager.error(orderStatusError.message, '', 3000);
          setConfirmCancelClicked(false);
        }
        else {
          NotificationManager.success('Order has been cancelled', '', 3000);
          setConfirmCancelClicked(false);
          dispatch(singleOrder(selectedOrderId));
          // history.push('/orders');
        }
      }
      else if (
        singleOrderDetails && singleOrderDetails.data && singleOrderDetails.data.result && singleOrderDetails.data.result.length > 0
      ) {
        console.log(singleOrderDetails)
        setSingleOrderData(singleOrderDetails);
      }
    }
  }, [isFetching, singleOrderDetails, confirmProcessClicked, confirmCancelClicked, orderStatusError]);


  const toggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const shortenText = (text, maxLength) => {
    if (typeof text === 'undefined' || text === null) {
      return '';
    }

    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + '...';
    }
  };



  const handleClose = () => {
    setshowProcessAlert(false);
    setShowCancelAlert(false);
  }

  const backToOrders = () => {
    history.push('/orders');
  };

  const orderCancel = (selectedOrderId) => {
    setOrderId(selectedOrderId)
    setShowCancelAlert(true)
  }
  const onConfirmCancelOrder = () => {
    setShowCancelAlert(false);
    setConfirmCancelClicked(true);
    dispatch(cancelOrder(selectedOrderId));
  };

  const processOrder = (selectedOrderId) => {
    setOrderId(selectedOrderId)
    setshowProcessAlert(true);
  };

  const onConfirmProcessOrder = () => {
    setConfirmProcessClicked(true);
    setshowProcessAlert(false);
    dispatch(orderProcess(selectedOrderId));
  };

  return (
    <>
      {
        showProcessAlert && <Modal
          isOpen={showProcessAlert}
          color="default"
          title="Confirmation !"
          btn="default"
          message={`Are you sure to process this order`}
          onModalSubmit={() => {
            onConfirmProcessOrder();
          }}
          onHide={() => {
            handleClose();
          }}
        />
      }

      {
        showCancelAlert && <Modal
          isOpen={showCancelAlert}
          color="default"
          title="Warning !"
          btn="default"
          message={`Are you sure to cancel this order`}
          onModalSubmit={() => {
            onConfirmCancelOrder();
          }}
          onHide={() => {
            handleClose();
          }}
        />
      }
      < Col md={12} lg={12} >
        <Card>
          <CardBody className="invoice">
            <div className="invoice__head">
              {singleOrderDetails.result?.map((d) => {
                return (
                  <>
                    <div className="invoice__head-left">
                      <h4>{d.orderNumber}</h4>
                      <p>{d.userName}</p>
                      <p>{d.companyName}</p>
                      <p>{d.phoneNo}</p>
                      <p>{d.email}</p>
                      <p className="invoice__date">{d.orderDate}</p>
                      <div className="invoice__head-order" style={{width:"300px"}}>
                      {showFullContent ? (
                        <p>Order Note: {d.orderNote}</p>
                      ) : (
                        <p>Order Note: {shortenText(d.orderNote, 20)}</p>
                      )}
                      {d.orderNote.length > 20 && (
                              <button onClick={toggleContent} 
                              className="cart__table-btn "
                              style={{paddingLeft:"0px"}}
                              type="button"
                              >
                                {showFullContent ? 'view less' : 'view more'}
                              </button>
                            )}
                        </div>
                    </div>

                    <div className="invoice__head-right">
                      <p>Order Type: {d.orderType}</p>
                      <p>Delivery Type: {d.deliveryType}</p>
                      <p>Delivery Date: {d.expectedDeliveryDate}</p>
                      <p>Status: {d.status}</p>

                    </div>
                  </>
                );
              })}

            </div>
            <Table className="table--bordered" responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Product</th>
                  <th>Quantity</th>

                </tr>
              </thead>
              <tbody>
                {singleOrderDetails.result?.map((d) => {
                  return (
                    d.orderItems.map((orderItem, index) => {
                      return (
                        <tr key={`index_${orderItem.title}`}>
                          <td>{index + 1}</td>
                          <td>{orderItem.displayName}</td>
                          <td>{orderItem.quantity}</td>
                        </tr>
                      )
                    })
                  )
                })}
                {/* {singleOrderData.map((item, index) => (
                  <tr key={`index_${item.title}`}>
                    <td>{index + 1}</td>
                    <td>{item.product}</td>
                    <td>{item.quantity}</td>
                  </tr>
                ))} */}
              </tbody>
            </Table>
            {singleOrderDetails.result?.map((d) => {
              return (
                <div className="invoice__total">
                  {d.statusType == 1 && <Button color="success" onClick={() => processOrder(selectedOrderId)} >Process Order</Button>}
                  {d.statusType == 1 && <Button color="danger" onClick={() => orderCancel(selectedOrderId)}>Cancel Order</Button>}
                  <Button onClick={backToOrders} >Back to Orders</Button>
                </div>
              )
            })}
          </CardBody>
        </Card>
      </Col >
    </>
  );
};

export default InvoiceTemplate;
