import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { connect } from 'react-redux';
import { UserProps } from '../../../../shared/prop-types/ReducerProps';
import TopbarMenuLink from './TopbarMenuLink';

const Ava = `${process.env.PUBLIC_URL}/img/admin_logo.png`;

const TopbarProfile = ({ user }) => {
  const history = new useHistory();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logout = () => {
    window.localStorage.clear();
    history.push('/log_in');
  }

  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        <img
          className="topbar__avatar-img"
          src={(user && user.avatar) || Ava}
          alt="avatar"
        />
        <p className="topbar__avatar-name">
          {user && user.fullName}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          <TopbarMenuLink
            title="Change Password"
            icon="cog"
            path="/changepassword"
            onClick={toggleProfile}
          />
          <TopbarMenuLink
            title="Log Out"
            icon="exit"
            // path="/log_in"
            onClick={logout}
          />
        </div>
      </Collapse>
    </div>);
};

TopbarProfile.propTypes = {
  user: UserProps.isRequired,
};

export default connect(state => ({
  user: state.user,
}))(TopbarProfile);
