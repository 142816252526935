import React from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';

const TotalOrders = props => {
  return (<Col md={12} xl={3} lg={6} xs={12}>
    <Card>
      <CardBody className="dashboard__booking-card">
        <h5 className="dashboard__booking-total-description"> </h5>
        <div className="dashboard__booking-total-container">
          <h5 className="dashboard__booking-total-title dashboard__booking-total-title--orange">
            {props.count}
          </h5>
        </div>
        <h5 className="dashboard__booking-total-description">Total Orders</h5>
      </CardBody>
    </Card>
  </Col>
  );
};

export default TotalOrders;
