import React from 'react';
import {
  Card, CardBody, Col, Badge, Table,
} from 'reactstrap';
import Panel from '../../../shared/components/Panel';

const header = [
  { id: 1, title: 'Order Number' },
  { id: 2, title: 'Customer' },
  { id: 3, title: 'Date' },
  { id: 4, title: 'Order Type' },
  { id: 5, title: 'Delivery' },
  { id: 6, title: 'Requested Delivery Date' },
  { id: 7, title: 'Status' },
];

const RecentOrders = props => {
console.log(props)
  return (
    <Panel
      lg={6}
      xl={12}
      xs={12}
      title="Recent Orders"
      subhead=""
    >
      <Table responsive className="table--bPending">
        <thead>
          <tr>
            {header.map(item => (
              <th align='center' key={item.id}>{item.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {props.recentOrders.map(item => (
            <tr>
              <td>{item.orderNumber}</td>
              <td>{item.customer_name}</td>
              <td>{item.orderDate}</td>
              <td>{item.orderType}</td>
              <td>{item.deliveredAt}</td>
              <td>{item.expectedDeliveryDate}</td>
              <td><Badge color={item.badge}>{item.status}</Badge></td>
            </tr>
          ))}
        </tbody>
      </Table>

    </Panel>
  );
};

export default RecentOrders;
