import { handleActions } from 'redux-actions';
import {
  deleteUserMailOTPRequest, deleteUserMailOTPSuccess, deleteUserMailOTPFailed,
  verifyDeleteOtpRequest, verifyDeleteOtpSuccess, verifyDeleteOtpFailed,
  deleteUserAccountRequest, deleteUserAccountSuccess, deleteUserAccountFailed

} from '../actions/deleteAccountAction';

const defaultState = {
  data: {},
  verifyOtpData: {},
  deleteData: {},
  isFetching: false,
  isOTPSend: false,
  isOTPVerified: false,
  isDeleted: false,
  error: null,
  verifyOtpError: null,
  deleteError: null
};

export default handleActions(
  {
    [deleteUserMailOTPRequest](state) {
      return {
        ...state,
        isFetching: true,
        isOTPSend: false,
        error: null,
      };
    },
    [deleteUserMailOTPSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isOTPSend: true,
        error: null,
      };
    },
    [deleteUserMailOTPFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isOTPSend: false,
        error: payload,
      };
    },
    [verifyDeleteOtpRequest](state, { payload }) {
      return {
        ...state,
        isFetching: true,
        isOTPVerified: false,
        verifyOtpError: null,
      };
    },
    [verifyDeleteOtpSuccess](state, { payload }) {
      return {
        ...state,
        verifyOtpData: payload,
        isFetching: false,
        isOTPVerified: true,
        verifyOtpError: null,
      };
    },
    [verifyDeleteOtpFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isOTPVerified: false,
        verifyOtpError: payload,
      };
    },
    [deleteUserAccountRequest](state, { payload }) {
      return {
        ...state,
        isFetching: true,
        isDeleted: false,
        deleteError: null,
      };
    },
    [deleteUserAccountSuccess](state, { payload }) {
      return {
        ...state,
        deleteData: payload,
        isFetching: false,
        isDeleted: true,
        deleteError: null,
      };
    },
    [deleteUserAccountFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isDeleted: false,
        deleteError: payload,
      };
    },
  },
  defaultState,
);