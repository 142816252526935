import { createAction } from 'redux-actions';
import productsApi from '../../utils/api/productsApi';

export const getDataRequest = createAction('FETCH_PRODUCTS_REQUEST');
export const getDataSuccess = createAction('FETCH_PRODUCTS_SUCCESS');
export const getDataFailed = createAction('FETCH_PRODUCTS_FAILED');

export const imageUploadRequest = createAction('IMAGE_UPLOAD_REQUEST');
export const imageUploadSuccess = createAction('IMAGE_UPLOAD_SUCCESS');
export const imageUploadFailed = createAction('IMAGE_UPLOAD_FAILED');

export const deleteProductRequest = createAction('DELETE_PRODUCT_REQUEST');
export const deleteProductSuccess = createAction('DELETE_PRODUCT_SUCCESS');
export const deleteProductFailed = createAction('DELETE_PRODUCT_FAILED');

export const saveProductRequest = createAction('SAVE_PRODUCTS_REQUEST');
export const saveProductSuccess = createAction('SAVE_PRODUCTS_SUCCESS');
export const saveProductFailed = createAction('SAVE_PRODUCTS_FAILED');

export const getSingleDataRequest = createAction('FETCH_SINGLE_PRODUCT_REQUEST');
export const getSingleDataSuccess = createAction('FETCH_SINGLE_PRODUCT_SUCCESS')
export const getSingleDataFailed = createAction('FETCH_SINGLE_PRODUCT_FAILED')

export const editProductRequest = createAction('EDIT_PRODUCT_REQUEST');
export const editProductSuccess = createAction('EDIT_PRODUCT_SUCCESS');
export const editProductFailed = createAction('EDIT_PRODUCT_SUCCESS');

export const removeImageRequest = createAction('FETCH_IMAGE_REMOVE_REQUEST')
export const removeImageSuccess = createAction('FETCH_IMAGE_REMOVE_SUCCESS')
export const removeImageFailed = createAction('FETCH_IMAAGE_REMOVE_FAILED')

export const getSingleDataWithVarientRequest = createAction('FETCH_DATA_WITH_VARIENT_REQUEST')
export const getSingleDataWithVarientSuccess = createAction('FETCH_DATA_WITH_VARIENT_SUCCESS')
export const getSingleDataWithVarientFailed = createAction('FETCH_DATA_WITH_VARIENT_FAILED')

export const getAllProducts = (searchText, sortName, sortOrder, currentPage, pageLimit) => async (dispatch) => {
  searchText = searchText ? searchText : '';
  try {
    let param = '?sortOrder=' + sortOrder +
      "&sortName=" + sortName +
      "&currentPage=" + currentPage +
      "&pageLimit=" + pageLimit +
      "&keyword=" + searchText;
    dispatch(getDataRequest());
    const results = await productsApi.getAllProducts(param);
    console.log(results)
    if (results && results.error) {
      dispatch(getDataFailed(results.error));
    }
    else if (results.data) {
      dispatch(getDataSuccess(results.data));
    }
    else {
      dispatch(getDataFailed(results));
    }
  } catch (e) {
    console.log(e)
    dispatch(getDataFailed(e));
  }
};

export const deleteProduct = (productId) => async (dispatch) => {
  try {
    dispatch(deleteProductRequest());
    const results = await productsApi.deleteProducts(productId);
    console.log(results)
    if (results && results.error) {
      dispatch(deleteProductFailed(results.error));
    }
    else if (results) {
      dispatch(deleteProductSuccess(results));
    }
    else {
      dispatch(deleteProductFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(deleteProductFailed(e));
  }
};

export const saveProductWithImage = (productDetails) => async (dispatch) => {
  try {
    dispatch(saveProductRequest());
    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'));
    await fetch(process.env.REACT_APP_BASE_URL + 'product/saveProductWithImage', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + adminInfo.token
      },
      body: productDetails,
    }).then((response) => {
      let resp = response.json()
      resp.then((data) => {
        console.log(data)
        if (data.errorCode > 0) {
          dispatch(saveProductFailed(data));
        }
        else {
          dispatch(saveProductSuccess(data));
        }
      })
      if (response == undefined) {
        dispatch(saveProductFailed({}));
      }
    }).catch((ex) => {
      dispatch(saveProductFailed({}));
    })
  } catch (e) {
    console.log(e)
    dispatch(saveProductFailed(e));
  }
};

export const saveProduct = (productId, productName, description, varientTypes, productSku, image) => async (dispatch) => {
  try {
    console.log(varientTypes)
    dispatch(saveProductRequest());
    const results = await productsApi.saveProduct(productId, productName, description, varientTypes, productSku, image);
    console.log(results.data)
    if (results && results.data && results.data.errorCode !== 0) {
      dispatch(saveProductFailed(results.data));
    }
    else if (results && results.data && results.data.result) {
      dispatch(saveProductSuccess(results.data));
    }
    else {
      dispatch(saveProductFailed(results.data));
    }
  } catch (e) {
    console.log(e)
    dispatch(saveProductFailed(e));
  }
};

export const singleProduct = (productId) => async (dispatch) => {
  try {
    dispatch(getSingleDataRequest());
    const results = await productsApi.getSingleProduct(productId);
    console.log(results)
    if (results && results.error) {
      dispatch(getSingleDataFailed(results.error));
    }
    else if (results && results.data) {
      dispatch(getSingleDataSuccess(results.data));
    }
    else {
      dispatch(getSingleDataFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(getSingleDataFailed(e));
  }
};

export const singleProductWithVarient = (productId) => async (dispatch) => {
  try {
    dispatch(getSingleDataWithVarientRequest());
    const results = await productsApi.getSingleProductwithVarient(productId);
    console.log(results)
    if (results && results.error) {
      dispatch(getSingleDataWithVarientFailed(results.error));
    }
    else if (results && results.data) {
      dispatch(getSingleDataWithVarientSuccess(results.data));
    }
    else {
      dispatch(getSingleDataWithVarientFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(getSingleDataFailed(e));
  }
};

export const editProduct = (productDetails) => async (dispatch) => {
  try {
    dispatch(editProductRequest());
    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'));
    await fetch(process.env.REACT_APP_BASE_URL + `product/updateProductWithImage`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + adminInfo.token
      },
      body: productDetails,
    }).then((response) => {
      let resp = response.json()
      resp.then((data) => {
        if (data.errorCode > 0) {
          dispatch(editProductFailed(data));
        }
        else {
          dispatch(editProductSuccess(data));
        }
      })
      if (response === undefined) {
        dispatch(editProductFailed({}));
      }
    }).catch((ex) => {
      dispatch(editProductFailed({}));
    })
  } catch (e) {
    console.log(e)
    dispatch(editProductFailed(e));
  }
};

export const removeImage = (userId) => async (dispatch) => {
  try {
    dispatch(removeImageRequest());
    const results = await productsApi.removeImage(userId);
    console.log(results)
    if (results && results.error) {
      dispatch(removeImageFailed(results.error));
    }
    else if (results) {
      dispatch(removeImageSuccess(results));
    }
    else {
      dispatch(removeImageFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(removeImageFailed(e));
  }
};