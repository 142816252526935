import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Card, Col, Button, ButtonToolbar, Table, CardBody } from "reactstrap";
import Alert from "../../../shared/components/Alert";
import Modal from "../../../shared/components/Modal";
import { singleUser } from "../../../redux/actions/userActions";

const UserTemplate = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const singleUserDetails = useSelector((state) => state.users.singleData);
  //const orderProcess = useSelector((state) => state.orders.isOrderProcess);
  console.log(singleUserDetails);

  const [singleUserData, setSingleUserData] = useState([]);
  const [userId, setUserId] = useState();

  console.log(singleUserData);

  const selectedUserId = location.state.userId;

  useEffect(() => {
    setUserId(selectedUserId);
    let adminObj = JSON.parse(localStorage.getItem("adminInfo"));
    if (adminObj) {
      dispatch(singleUser(selectedUserId));
    } else {
      history.push("/log_in");
    }
  }, [dispatch, history, selectedUserId]);

  useEffect(() => {
    if (singleUserDetails) {
      console.log(singleUserDetails);
      setSingleUserData(singleUserDetails);
    }
  }, [singleUserDetails]);

  const backToUsers = () => {
    history.push("/users");
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          {singleUserDetails.user?.map((d) => {
            let companyImage = d.companyImage ? `${d.companyImage.toString('base64')}` : '';
            return <div className="product-card">
              <div className="product-gallery">
                <button type="button" className="product-gallery__current-img">
                  <img src={companyImage} alt="company-img" />
                </button>
              </div>
              <div className="product-card__info">
                <h3 className="product-card__title">{d.name} - {d.accountNo}</h3>
                <h4 className="product-card__price">{d.companyName}</h4>
                <h5 className="product-card__price">{d.companyAddress}</h5>
                <h5 className="product-card__price">{d.email}</h5>
                <h5 className="product-card__price">{d.phoneNo}</h5>
              </div>
            </div>
          })}
          <div className="invoice__total">
            <Button onClick={backToUsers}>Back to Users</Button>
          </div>
        </CardBody>
      </Card>
    </Col >
  );
};

export default UserTemplate;
