const BasicTableData = () => {
  const header = [
    { id: 1, title: 'Variant Type' },
    { id: 2, title: 'Min Order' },
    { id: 3, title: 'SKU Number' },
  ];

  const headerResponsive = [
    { id: 1, title: 'Varient Type' },
    { id: 2, title: 'Min Order' },
    { id: 3, title: 'SKU Number' },
  ];

  const rows = [{
    isSelect: false,
    varientTypeId: 1,
    varientType: '20KG',
    minOrder: '',
    skuNumber: '',
  }, {
    isSelect: true,
    varientTypeId: 2,
    varientType: '25KG',
    minOrder: '40',
    skuNumber: 'MCP-25KG',

  }, {
    isSelect: true,
    varientTypeId: 3,
    varientType: '0.5MT',
    minOrder: '1',
    skuNumber: 'MCP-0.5MT',
  }, {
    isSelect: true,
    varientTypeId: 4,
    varientType: '1MT',
    minOrder: '1',
    skuNumber: 'MCP-1MT',
  }];

  const basicTableData = { tableHeaderData: header, tableHeaderResponsiveData: headerResponsive, tableRowsData: rows };
  return basicTableData;
};

export default BasicTableData;
