import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ProductDetails from './components/ProductTemplate';
import BreadCrumb from '../../shared/components/BreadCrumb';

const ProductTemplate = () => (
  <Container>
    <Row>
      <Col md={12}>
        <BreadCrumb title="Product Details"/>
      </Col>
    </Row>
    <Row>
      <ProductDetails />
    </Row>
  </Container>
);

export default ProductTemplate;
