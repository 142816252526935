import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Badge, Card, CardBody, Col } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import CancelIcon from "mdi-react/CancelIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import OrderTableHead from "./OrderTableHead";
import { getAllOrders, singleOrder } from "../../../redux/actions/orderActions";

const OrdersTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const orderData = useSelector((state) => state.orders.data);

  const [searchText, setSearchText] = useState("");
  const [sortName, setSortName] = useState("orderNumber");
  const [sortOrder, setSortOrder] = useState("desc");
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [orderId, setOrderId] = useState();
  const [showFullContent, setShowFullContent] = useState(false);


  // console.log(data);

  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem("adminInfo"));
    if (adminObj) {
      dispatch(
        getAllOrders(searchText, sortName, sortOrder, page, rowsPerPage)
      );
    } else {
      history.push("/log_in");
    }
  }, []);

  useEffect(() => {
    dispatch(getAllOrders(searchText, sortName, sortOrder, page, rowsPerPage));
  }, [sortName, sortOrder, page, rowsPerPage]);

  useEffect(() => {
    console.log(searchText.length)
    if (searchText) {
      dispatch(getAllOrders(searchText, sortName, sortOrder, page, rowsPerPage));
    } else {
      dispatch(getAllOrders('', sortName, sortOrder, page, rowsPerPage));
    }
  }, [searchText]);

  useEffect(() => {
    if (orderData && orderData.orders && orderData.orders.length > 0) {
      setData(orderData.orders);
    }
  }, [orderData]);

  const shortenText = (text, maxLength) => {
    if (typeof text === 'undefined' || text === null) {
      return '';
    }

    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + '...';
    }
  };




  const handleRequestSort = (event, property) => {
    let orderTemp = "desc";
    if (sortName === property && sortOrder === "desc") {
      orderTemp = "asc";
    }
    setSortName(property);
    setSortOrder(orderTemp);
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      const newSelected = new Map();
      data.map((n) => newSelected.set(n.id, true));
      setSelected(newSelected);
      return;
    }
    setSelected(new Map([]));
  };

  const handleClick = (event, id) => {
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    setSelected(newSelected);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  const handleSearch = () => {
    // dispatch(getAllUsers(searchText, sortName, sortOrder, page, rowsPerPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleDeleteSelected = () => {
    let copyData = [...data];
    for (let i = 0; i < [...selected].filter((el) => el[1]).length; i += 1) {
      copyData = copyData.filter((obj) => obj.id !== selected[i]);
    }
    setData(copyData);
    setSelected(new Map([]));
  };

  const handleOnViewClick = (orderId) => {
    setOrderId(orderId);
    dispatch(singleOrder(orderId));
    history.push({ pathname: "/vieworder", state: { orderId: orderId } });
  };

  const isSelected = (id) => !!selected.get(id);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  console.log(orderData, "order data")
  // console.log(orderData.orders.length,"length")
  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <div className="inbox__emails-control-search">
              <input
                placeholder="Search Order No."
                name="search"
                type="text"
                maxLength={6}
                value={searchText}
                onChange={(evt) => setSearchText(+evt.target.value === +evt.target.value ? evt.target.value : searchText)}
              />
              <div
                className="inbox__emails-control-search-icon"
                onClick={handleSearch}
              >
                <MagnifyIcon />
              </div>
            </div>
            {/* <h5 className="bold-text">Orders List</h5> */}
          </div>
          {/* <MatTableToolbar
            numSelected={[...selected].filter(el => el[1]).length}
            handleDeleteSelected={handleDeleteSelected}
            onRequestSort={handleRequestSort}
          /> */}
          {orderData.orders && orderData.orders.length > 0 ? (

            <div>
              <div className="material-table__wrap">
                <Table className="material-table">
                  <OrderTableHead
                    numSelected={[...selected].filter((el) => el[1]).length}
                    order={sortOrder}
                    orderBy={sortName}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={orderData.orders.length}
                  />
                  <TableBody>
                    {orderData.orders.map((d) => {
                      const select = isSelected(d.id);
                      return (
                        <TableRow
                          className="material-table__row"
                          role="checkbox"
                          onClick={(event) => handleClick(event, d.id)}
                          aria-checked={select}
                          tabIndex={-1}
                          key={d.id}
                          selected={select}
                        >
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                            component="th"
                            scope="row"
                            padding="none"
                          >
                            {d.orderNumber}
                          </TableCell>
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.customer_name}
                          </TableCell>
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.orderDate}
                          </TableCell>
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.orderType}
                          </TableCell>
                          <TableCell
                            className="material-table__cell material-table__cell-right"
                          >
                            <a type="button"  href="javascript:void(0);" data-toggle="tooltip" data-placement="top" class="text-muted" title={d.orderNote }>
                            {shortenText(d.orderNote, 20)}
                            </a>
                          </TableCell>
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.deliveryType}
                          </TableCell>
                          <TableCell className="material-table__cell material-table__cell-right">
                            {d.expectedDeliveryDate}
                          </TableCell>
                          <TableCell>
                            <Badge color={d.badge}>{d.status}</Badge>
                          </TableCell>

                          <TableCell>
                            <button
                              onClick={() => handleOnViewClick(d.orderId)}
                              className="cart__table-btn"
                              type="button"
                            >
                              <EyeOutlineIcon tooltip="view" /> View
                            </button>
                          </TableCell>

                          {/* <TableCell>
                          <button className="cart__table-btn" type="button">
                            <CancelIcon /> Cancel
                          </button>
                        </TableCell> */}
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 2 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
              <TablePagination
                component="div"
                className="material-table__pagination"
                count={orderData.total[0].total}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{ "aria-label": "Previous Page" }}
                nextIconButtonProps={{ "aria-label": "Next Page" }}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 15]}
                dir="ltr"
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
              />
            </div>
          ) : (
            <div>No Data found</div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default OrdersTable;
