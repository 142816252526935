import { createAction } from 'redux-actions';
import dashboardApi from '../../utils/api/dashboardApi';

export const getDataRequest = createAction('LOGIN_REQUEST');
export const getDataSuccess = createAction('LOGIN_SUCCESS');
export const getDataFailed = createAction('LOGIN_FAILED');

export const dashboardDetails = () => async (dispatch) => {
  try {
    dispatch(getDataRequest());
    const results = await dashboardApi.getDashboardDetails();
    if (results && results.error) {
      dispatch(getDataFailed(results.error));
    }
    else if (results.data && results.data.data) {
      dispatch(getDataSuccess(results.data.data));
    }
    else {
      dispatch(getDataFailed(results));
    }
  } catch (e) {
    console.log(e)
    dispatch(getDataFailed(e));
  }
};
