import { handleActions } from 'redux-actions';
import {
  getDataRequest, getDataSuccess, getDataFailed,
} from '../actions/dashboardActions';

const defaultState = {
  data: {},
  isFetching: false,
  isDashboardDataFetched: false,
  error: null,
};

export default handleActions(
  {
    [getDataRequest](state) {
      return {
        ...state,
        isFetching: true,
        isDashboardDataFetched: false,
        error: null,
      };
    },
    [getDataSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isDashboardDataFetched: true,
        error: null,
      };
    },
    [getDataFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isDashboardDataFetched: false,
        error: payload,
      };
    },
  },
  defaultState,
);
