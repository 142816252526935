import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  BarChart, Bar, ResponsiveContainer, Tooltip, XAxis, YAxis, CartesianGrid,
} from 'recharts';

import Panel from '../../../shared/components/Panel';
import getTooltipStyles from '../../../shared/helpers';

const SalesStatisticBar = ({ themeName, barChartInfo }) => {
  const { t } = useTranslation('common');
  const [isYearly, setYearly] = useState(true);

  const handleChange = () => {
    setYearly(!isYearly);
  };
 // get current year
const currentDate = new Date();
const currentYear = currentDate.getFullYear();



  return (
    <Panel
      xl={4}
      lg={7}
      md={12}
      title={`Order Statics - ${currentYear}`}
      subhead="See how effective your business is"
    >
      <div dir="ltr">
        <ResponsiveContainer height={260}>
          {isYearly
            ? (
              <BarChart data={barChartInfo.monthly} margin={{ top: 20, left: -15 }}>
                <XAxis dataKey="month" tickLine={false} />
                <YAxis tickLine={false} />
                <Tooltip {...getTooltipStyles(themeName, 'defaultItems')} />
                <CartesianGrid vertical={false} />
                <Bar dataKey="count" name="Orders" fill="#ff4861" barSize={10} />
              </BarChart>
            ) : (
              <BarChart data={barChartInfo.daily} margin={{ top: 20, left: -15 }}>
                <XAxis dataKey="date" tickLine={false} />
                <YAxis tickLine={false} />
                <Tooltip {...getTooltipStyles(themeName, 'defaultItems')} />
                <CartesianGrid vertical={false} />
                <Bar dataKey="count" name="Orders" fill="#4ce1b6" barSize={4} />
              </BarChart>
            )}
        </ResponsiveContainer>
      </div>
      <hr />
      <label htmlFor="sales" className="toggle-btn dashboard__sales-toggle">
        <input className="toggle-btn__input" type="checkbox" name="sales" id="sales" onChange={handleChange} />
        <span className="dashboard__sales-toggle-left">Yearly</span>
        <span className="toggle-btn__input-label" />
        <span className="dashboard__sales-toggle-right">Monthly</span>
      </label>
    </Panel>
  );
};

SalesStatisticBar.propTypes = {
  themeName: PropTypes.string.isRequired,
};

export default connect(state => ({ themeName: state.theme.className }))(SalesStatisticBar);
