import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ButtonToolbar, Badge, Card, CardBody, Col } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import EditIcon from "mdi-react/EditIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import ProductTableHead from "./ProductTableHead";
import Modal from "../../../shared/components/Modal";
import { NotificationManager } from "react-notifications";
import ViewProductModal from "../../../shared/components/ViewProductModal";

import {
  getAllProducts,
  deleteProduct,
} from "../../../redux/actions/productActions";

const ProductsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isFetching = useSelector((state) => state.product.isFetching);
  const productsData = useSelector((state) => state.product.data);
  const productDelete = useSelector((state) => state.product.isProductDeleted);
  const productDeleteError = useSelector((state) => state.product.error);

  const [searchText, setSearchText] = useState("");
  const [sortName, setSortName] = useState("productName");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [productId, setProductId] = useState();
  const [Id, setId] = useState();
  const [selectedProductName, setSelectedProductName] = useState();
  const [deleteProductrModal, setDeleteProductModal] = useState(false);
  const [viewProductrModal, setViewProductModal] = useState(false);
  const [deleteConfirmClicked, setDeleteConfirmClicked] = useState(false);

  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem("adminInfo"));
    if (adminObj) {
      dispatch(getAllProducts(searchText, sortName, sortOrder, page, rowsPerPage));
    } else {
      history.push("/log_in");
    }
  }, []);

  useEffect(() => {
    if (!isFetching) {
      if (deleteConfirmClicked) {
        if (productDeleteError) {
          NotificationManager.error(productDeleteError.message, "", 3000);
          setDeleteConfirmClicked(false);
        }
        else {
          NotificationManager.success("Product deleted.", "", 3000);
          setDeleteConfirmClicked(false);
          dispatch(getAllProducts(searchText, sortName, sortOrder, page, rowsPerPage));
        }
      }
      else {
        setData(productsData);
      }
    }
  }, [isFetching, productsData, productDeleteError, deleteConfirmClicked]);

  useEffect(() => {
    if (productDelete) {
    }
    dispatch(getAllProducts(searchText, sortName, sortOrder, page, rowsPerPage));
  }, [searchText, sortName, sortOrder, page, rowsPerPage]);

  const handleRequestSort = (event, property) => {
    let orderTemp = "desc";
    if (sortName === property && sortOrder === "desc") {
      orderTemp = "asc";
    }
    setSortName(property);
    setSortOrder(orderTemp);
  };

  const handleSearch = () => {
    // dispatch(getAllUsers(searchText, sortName, sortOrder, page, rowsPerPage));
  };

  const handleClose = () => {
    setDeleteProductModal(false);
    setViewProductModal(false);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  const handleDeleteSelected = (productId, id, productName) => {
    setProductId(productId);
    setId(id)
    setSelectedProductName(productName);
    setDeleteProductModal(true);
  };

  const onDeleteConfirmClick = () => {
    setDeleteProductModal(false);
    setDeleteConfirmClicked(true);
    dispatch(deleteProduct(productId));
  };

  const sendViewProduct = () => {
    setViewProductModal(true);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleOnViewClick = (productId) => {
    setProductId(productId)
    history.push({ pathname: '/viewproduct', state: { productId: productId } });
  };

  const handleEditClick = (productId) => {
    setId(productId)
    history.push({ pathname: '/editProduct', state: { productId: productId } });
  };

  const isSelected = (id) => !!selected.get(id);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <>
      {deleteProductrModal && (
        <Modal
          isOpen={deleteProductrModal}
          color="default"
          title="Warning !"
          btn="default"
          message={`Are you sure to delete ${selectedProductName} `}
          onModalSubmit={() => {
            onDeleteConfirmClick();
          }}
          onHide={() => {
            handleClose();
          }}
        />
      )}

      {viewProductrModal && (
        <ViewProductModal
          isOpen={viewProductrModal}
          color="default"
          title="Product Details !"
          btn="default"
          message={`Are you sure to delete ${selectedProductName} `}
          onModalSubmit={() => {
            sendViewProduct();
          }}
          onHide={() => {
            handleClose();
          }}
        />
      )}

      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <div className="inbox__emails-control-search">
                <input
                  placeholder="Search"
                  name="search"
                  type="text"
                  value={searchText}
                  onChange={(evt) => setSearchText(evt.target.value)}
                />
                <div
                  className="inbox__emails-control-search-icon"
                  onClick={handleSearch}
                >
                  <MagnifyIcon />
                </div>
              </div>
              {/* <h5 className="bold-text">Products List</h5> */}
              <ButtonToolbar className="products-list__btn-toolbar-top">
                <Link
                  className="btn btn-primary products-list__btn-add"
                  to="/saveProduct"
                >
                  Create Product
                </Link>
              </ButtonToolbar>
            </div>
            {productsData.product && productsData.product.length > 0 ? (
              <div>
                <div className="material-table__wrap">
                  <Table className="material-table">
                    <ProductTableHead
                      numSelected={[...selected].filter((el) => el[1]).length}
                      order={sortOrder}
                      orderBy={sortName}
                      onSelectAllClick
                      onRequestSort={handleRequestSort}
                      rowCount={productsData.product.length}
                    />
                    {<TableBody>
                      {productsData.product.map((d) => {
                        const select = isSelected(d.id);
                        return (
                          <TableRow
                            className="material-table__row"
                            aria-checked={select}
                            tabIndex={-1}
                            key={d.id}
                            selected={select}
                          >
                            <TableCell
                              className="material-table__cell material-table__cell-right"
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              {d.skuNumber}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.productDisplayName}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.minOrderQty}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.productType}
                            </TableCell>
                            <TableCell>
                              <button
                                onClick={() =>
                                  handleOnViewClick(
                                    d.productskuId,
                                    d.productDisplayName,
                                    d.productType
                                  )
                                }
                                className="cart__table-btn"
                                type="button"
                              >
                                <EyeOutlineIcon /> View
                              </button>
                            </TableCell>
                            <TableCell>
                              <button
                                onClick={() =>
                                  handleEditClick(
                                    d.productId
                                  )
                                }
                                className="cart__table-btn" type="button">
                                <EditIcon /> Edit
                              </button>
                            </TableCell>
                            <TableCell>
                              <button
                                className="cart__table-btn"
                                type="button"
                                onClick={() =>
                                  handleDeleteSelected(
                                    d.productskuId,
                                    d.productId,
                                    d.productDisplayName,
                                    d.productType
                                  )
                                }
                              >
                                <DeleteIcon /> Delete
                              </button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 49 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    }
                  </Table>
                </div>
                <TablePagination
                  component="div"
                  className="material-table__pagination"
                  count={productsData.total[0].total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15]}
                  dir="ltr"
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                />
              </div>
            ) : (
              <div>No Products found</div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ProductsTable;
