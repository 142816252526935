import React, { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
// import NotFound404 from '../../DefaultPage/404/index';
import LogIn from '../../Account/LogIn/index';
import ResetPassword from '../../Account/ResetPassword/index';
import NewPassword from '../../Account/NewPassword/index';
import WrappedRoutes from './WrappedRoutes';
import DeleteAccount from '../../Account/DeleteAccount/index';

const Router = () => {

  const [adminLoggedIn, setAdminLoggedIn] = useState(false);
  const [admin, setStateAdmin] = useState({});
  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem('adminInfo'));
    if (adminObj) {
      setStateAdmin(adminObj);
      setAdminLoggedIn(true);
    }
  }, []);

  return (
    <MainWrapper>
      <main>
        <Switch>

          {/* {adminLoggedIn && <Route path="/" component={WrappedRoutes} exact />} */}
          <Route exact path="/" component={LogIn} />
          {/* <Route path="/404" component={NotFound404} /> */}
          <Route path="/log_in" component={LogIn} />
          <Route path="/reset_password" component={ResetPassword} />
          <Route path="/new_password" component={NewPassword} />
          <Route path="/delete_user" component={DeleteAccount} />
          <Route path="/" component={WrappedRoutes} />
        </Switch>
      </main>
    </MainWrapper>
  )
};

export default Router;
