import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, ModalHeader, ModalBody, Button,
} from 'reactstrap';
import LogInForm from './loginForm/LogInForm';

const ModalLoginForm = ({
  title, isOpen, error, closeModal, onLogin,
}) => (
  <Modal toggle={closeModal} className="theme-light ltr-support login-modal-form" isOpen={isOpen}>
    <ModalHeader>{title}</ModalHeader>
    <ModalBody>
      <LogInForm
        onSubmit={onLogin}
        errorMessage={error}
        form="modal_login"
        fieldUser="E-mail"
        typeFieldUser="email"
      />
      <div className="account__or">
        <p>Or Easily Using</p>
      </div>
      <div className="account__social">
        Social Media
      </div>
    </ModalBody>
  </Modal>
);

ModalLoginForm.propTypes = {
  title: PropTypes.string,
  error: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func.isRequired,
  onLogin: PropTypes.func.isRequired,
};

ModalLoginForm.defaultProps = {
  title: '',
  error: '',
  isOpen: false,
};

export default ModalLoginForm;
