import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import FirebaseIcon from 'mdi-react/FirebaseIcon';
import withAuthFirebase from '../../../shared/components/auth/withAuthFirebase';
import LogInForm from '../../../shared/components/loginForm/LogInForm';
import showResults from '../../../utils/showResults';

const LogIn = ({ changeIsOpenModalFireBase }) => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <LogInForm
          onSubmit={showResults}
          form="log_in_form"
        />
      </div>
    </div>
  </div>
);


export default withAuthFirebase(LogIn);
