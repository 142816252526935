import React, { useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import TotalOrders from './components/TotalOrders';
import ThisWeekOrders from './components/ThisWeekOrders';
import TotalCustomers from './components/TotalCustomers';
import TotalProducts from './components/TotalProducts';
import OrdersPieChart from './components/OrdersPieChart';
import WeeklyStat from './components/WeeklyStat';
import SalesStatisticBar from './components/SalesStatisticBar';
import BreadCrumb from '../../shared/components/BreadCrumb';
// import ProductOrder from './components/ProductOrder';
import RecentOrders from './components/RecentOrders';
import { dashboardDetails } from '../../redux/actions/dashboardActions';
import { useState } from 'react';

const Dashboard = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const dashboardError = useSelector(state => state.dashboard.error);
  const dashboardData = useSelector(state => state.dashboard.data);

  const [totalOrders, setTotalOrders] = useState(0);
  const [weeklyOrders, setWeeklyOrders] = useState(0);
  const [totalCustomers, setTotalCustomers] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [orderPieChartInfo, setOrderPieChartInfo] = useState({});
  const [weeklyStats, setWeeklyStats] = useState({});
  const [recentOrders, setRecentOrders] = useState({});
  const [ordersBarChartInfo, setOrdersBarChartInfo] = useState({});

  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem('adminInfo'));
    if (adminObj) {
      dispatch(dashboardDetails());
    }
    else {
      history.push("/log_in")
    }

  }, [dispatch, history]);

  useEffect(() => {
    console.log(dashboardData.weeklyStats)
    if (dashboardData.orderCounts) {
      setTotalOrders(dashboardData.orderCounts.totalOrders);
      setWeeklyOrders(dashboardData.orderCounts.ordersThisWeek);
      setTotalCustomers(dashboardData.orderCounts.usersCount);
      setTotalProducts(dashboardData.orderCounts.totalProducts);
      setOrderPieChartInfo(dashboardData.ordersPieChartInfo);
      setWeeklyStats(dashboardData.weeklyStats);
      setRecentOrders(dashboardData.recentOrders);
      setOrdersBarChartInfo(dashboardData.orderStatistics);
    }
  }, [dashboardData]);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
         <BreadCrumb title="Dashboard"/>
        </Col>
      </Row>
      <Row>
        <TotalOrders count={totalOrders} />
        <ThisWeekOrders count={weeklyOrders} />
        <TotalCustomers count={totalCustomers} />
        <TotalProducts count={totalProducts} />
      </Row>
      <Row>
        <OrdersPieChart dir="ltr" theme="theme-light" pieChartInfo={orderPieChartInfo} />
        {
          (weeklyStats && weeklyStats.processedOrders) && <WeeklyStat weeklyStats={weeklyStats} />
        }
        {ordersBarChartInfo && <SalesStatisticBar barChartInfo={ordersBarChartInfo} />}
        {/* <ProductOrder theme="theme-light" dir="ltr" /> */}
      </Row>
      <Row>
        {
          (recentOrders && recentOrders.length > 0) && < RecentOrders recentOrders={recentOrders} />
        }
      </Row>
    </Container>
  );
};


const mapStateToProps = (state) => {
  return ({
    rtl: state.rtl,
  });
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
