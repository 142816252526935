import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar, Col, Container, Row, Alert
}
  from 'reactstrap';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { Field, Form } from 'react-final-form';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import TagIcon from 'mdi-react/TagIcon';
import renderDropZoneMultipleField from '../../../shared/components/form/DropZoneMultiple';
import renderSelectField from '../../../shared/components/form/Select';
import BasicTable from './BasicTable';
import { NotificationManager } from "react-notifications";
import { savePromotion } from '../../../redux/actions/promotionsActions'
import { updatePassword } from '../../../redux/actions/authenticationActions'


const ChangePasswordForm = ({onSubmit}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("bhlfeeds@gmail.com");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [updatePasswordClicked, setupdatePasswordClicked] = useState(false);

  const isFetching = useSelector((state) => state.authentication.isFetching);
  const isUpdatePasswordError = useSelector(state => state.authentication.error);
  const isPasswordUpdated = useSelector(state => state.authentication.isPasswordUpdated);


  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem('adminInfo'));
    if (adminObj) {
    }
    else {
      history.push("/log_in")
    }

  }, [history]);

  useEffect(() => {
    if (updatePasswordClicked) {
      if (isPasswordUpdated) {
        NotificationManager.success("Password Reset Successfully.Please login again.", "", 5000);
        setupdatePasswordClicked(false)
        history.push('/log_in')
      }
      else if (isUpdatePasswordError) {
        NotificationManager.error("Password reset failed. Please try again", "", 3000);
        setupdatePasswordClicked(false)
      }
    }
  }, [isPasswordUpdated, isUpdatePasswordError]);

  const handleSubmit = () => {
    setupdatePasswordClicked(true)
    setPasswordError('');
    setConfirmPasswordError('');
    if (!password) {
      setPasswordError("Password Required")
    }
    else if (!confirmPassword) {
      setConfirmPasswordError("Password Required")
    }
    else if (password != confirmPassword) {
      setConfirmPasswordError("Password and Confirm Password should be same")
    }

    else {
      dispatch(updatePassword(email, password));
    }
  }

  const backToDashboard = () => {
    history.push('/dashboard');
  };

  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <form className="form product-edit"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Container>
            <Row>
              <Col md={12} lg={12} xl={7}>
                <div className="form__half">

                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <KeyVariantIcon />
                      </div>
                      <div class="form__form-group-input-wrap">
                        <input
                          name="password"
                          type="password"
                          placeholder="New Password"
                          value={password}
                          onChange={(evt) => setPassword(evt.target.value)}
                        />
                        {passwordError && <span className="form__form-group-error">{passwordError}</span>}
                      </div>
                    </div>
                  </div>
                  <div className="form__form-group">
                    <div className="form__form-group-field">
                      <div className="form__form-group-icon">
                        <KeyVariantIcon />
                      </div>
                      <div class="form__form-group-input-wrap">
                        <input
                          name="password"
                          type="password"
                          placeholder="Confirm Password"
                          value={confirmPassword}
                          onChange={(evt) => setConfirmPassword(evt.target.value)}
                        />
                        {confirmPasswordError && <span className="form__form-group-error">{confirmPasswordError}</span>}
                      </div>
                    </div>
                  </div>
                  <div>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" onClick={handleSubmit}>Send</Button>
                      <Button type="button" onClick={backToDashboard}>Cancel</Button>
                    </ButtonToolbar>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </Form>
  )
};



export default ChangePasswordForm;
