import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UsersTable from './components/UsersTable';
import BreadCrumb from '../../shared/components/BreadCrumb';

const MaterialTable = () => (
  <Container>
    <Row>
      <Col md={12}>
        <BreadCrumb title="Users"/>
      </Col>
    </Row>
    <Row>
      <UsersTable />
    </Row>
  </Container>
);

export default MaterialTable;
