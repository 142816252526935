import { createAction } from 'redux-actions';
import promotionsApi from '../../utils/api/promotionsApi';

export const getDataRequest = createAction('FETCH_ORDERS_REQUEST');
export const getDataSuccess = createAction('FETCH_ORDERS_SUCCESS');
export const getDataFailed = createAction('FETCH_ORDERS_FAILED');

export const savePromotionRequest = createAction('SAVE_PROMOTION_REQUEST');
export const savePromotionSuccess = createAction('SAVE_PROMOTION_SUCCESS');
export const savePromotionFailed = createAction('SAVE_PROMOTION_FAILED');

export const getAllPromotions = (searchText, sortName, sortOrder, currentPage, pageLimit) => async (dispatch) => {
  searchText = searchText ? searchText : '';
  try {
    let param = '?sortOrder=' + sortOrder +
      "&sortName=" + sortName +
      "&currentPage=" + currentPage +
      "&pageLimit=" + pageLimit +
      "&keyword=" + searchText;
    dispatch(getDataRequest());
    const results = await promotionsApi.getAllPromotions(param);
    console.log(results)
    if (results && results.error) {
      dispatch(getDataFailed(results.error));
    }
    else if (results.data && results.data.promotions) {
      dispatch(getDataSuccess(results.data));
    }
    else {
      dispatch(getDataFailed(results));
    }
  } catch (e) {
    console.log(e)
    dispatch(getDataFailed(e));
  }
};

export const savePromotion = (title,message) => async (dispatch) => {
  try {
    dispatch(savePromotionRequest());
    const results = await promotionsApi.savePromotion(title,message);
    console.log(results.data)
    if (results && results.data && results.data.errorCode !== 0) {
      dispatch(savePromotionFailed(results.data));
    }
    else if (results && results.data && results.data.promotion ) {
      dispatch(savePromotionSuccess(results.data));
    }
    else{
      dispatch(savePromotionFailed(results.data));
    }
  } catch (e) {
    console.log(e)
    dispatch(savePromotionFailed(e));
  }
};
