import { handleActions } from 'redux-actions';
import {
  getDataRequest, getDataSuccess, getDataFailed,
  deleteProductRequest, deleteProductSuccess, deleteProductFailed,
  saveProductRequest, saveProductSuccess, saveProductFailed,
  getSingleDataRequest, getSingleDataSuccess, getSingleDataFailed,
  getSingleDataWithVarientRequest, getSingleDataWithVarientSuccess, getSingleDataWithVarientFailed,
  imageUploadRequest, imageUploadSuccess, imageUploadFailed,
  editProductRequest, editProductSuccess, editProductFailed,
  removeImageRequest, removeImageSuccess, removeImageFailed
} from '../actions/productActions';

const defaultState = {
  data: {},
  singleData: {},
  isFetching: false,
  isProductsDataFetched: false,
  isImageUploaded: false,
  isUploading: false,
  isProductSaved: false,
  isProductDeleted: false,
  isSingleDataFetched: false,
  isImageRemoved: false,
  error: null,
};

export default handleActions(
  {
    [getDataRequest](state) {
      return {
        ...state,
        data:{},
        isFetching: true,
        isProductsDataFetched: false,
        error: null,
      };
    },
    [getDataSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isProductsDataFetched: true,
        error: null,
      };
    },
    [getDataFailed](state, { payload }) {
      return {
        ...state,
        data:{},
        isFetching: false,
        isProductsDataFetched: false,
        error: payload,
      };
    },
    [deleteProductRequest](state) {
      return {
        ...state,
        isFetching: true,
        isProductDeleted: false,
        error: null,
      };
    },
    [deleteProductSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        data: payload,
        isProductDeleted: true,
        error: null,
      };
    },
    [deleteProductFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isProductDeleted: false,
        error: payload,
      };
    },
    [imageUploadRequest](state) {
      return {
        ...state,
        isImageUploaded: false,
        error: null,
      };
    },
    [imageUploadSuccess](state, { payload }) {
      return {
        ...state,
        imageData: payload,
        isImageUploaded: true,
        error: null,
      };
    },
    [imageUploadFailed](state, { payload }) {
      return {
        ...state,
        isImageUploaded: false,
        error: payload,
      };
    },
    [saveProductRequest](state) {
      return {
        ...state,
        isFetching: true,
        isProductSaved: false,
        error: null,
      };
    },
    [saveProductSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isProductSaved: true,
        error: null,
      };
    },
    [saveProductFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isProductSaved: false,
        error: payload,
      };
    },
    [getSingleDataRequest](state) {
      return {
        ...state,
        isFetching: true,
        isSingleDataFetched: false,
        error: null,
        singleData: {},
      };
    },
    [getSingleDataSuccess](state, { payload }) {
      return {
        ...state,
        singleData: payload,
        isFetching: false,
        isSingleDataFetched: true,
        error: null,
      };
    },
    [getSingleDataFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isSingleDataFetched: false,
        error: payload,
        singleData: {},
      };
    },
    [getSingleDataWithVarientRequest](state) {
      return {
        ...state,
        singleData: {},
        isFetching: true,
        isSingleDataFetched: false,
        error: null,
      };
    },
    [getSingleDataWithVarientSuccess](state, { payload }) {
      return {
        ...state,
        singleData: payload,
        isFetching: false,
        isSingleDataFetched: true,
        error: null,
      };
    },
    [getSingleDataWithVarientFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isSingleDataFetched: false,
        error: payload,
      };
    },
    [editProductRequest](state) {
      return {
        ...state,
        isProductSaved: false,
        error: null,
      };
    },
    [editProductSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isProductSaved: true,
        error: null,
      };
    },
    [editProductFailed](state, { payload }) {
      return {
        ...state,
        isProductSaved: false,
        error: payload,
      };
    },
    [removeImageRequest](state) {
      return {
        ...state,
        isFetching: true,
        isImageRemoved: false,
        error: null,
      };
    },
    [removeImageSuccess](state, { payload }) {
      return {
        ...state,
        singleData: payload,
        isFetching: false,
        isImageRemoved: true,
        error: null,
      };
    },
    [removeImageFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isImageRemoved: false,
        error: payload,
      };
    },
  },
  defaultState,
);
