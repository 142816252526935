import React from 'react';
import { NavLink } from 'react-router-dom';
import showResults from '../../../utils/showResults';
import NewPasswordForm from '../../../shared/components/new_password/NewPasswordForm';

const bhlFeedsLogo = `${process.env.PUBLIC_URL}/img/bhl_logo.JPG`;

const NewPassword = props => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div>
          <img alt="logo" src={bhlFeedsLogo} />
        </div>
        <h3 className="account__subhead subhead">Reset password</h3>

        <NewPasswordForm
          {...props}
          onSubmit={showResults}
        />
        <div className="account__have-account">
          <p>Remember your password?  <NavLink to="/log_in">Login</NavLink></p>
        </div>
      </div>
    </div>
  </div>
);

export default NewPassword;
