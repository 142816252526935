import { handleActions } from 'redux-actions';
import {
  getDataRequest, getDataSuccess, getDataFailed,
  saveUserRequest, saveUserSuccess, saveUserFailed,
  deleteUserRequest, deleteUserSuccess, deleteUserFailed,
  resendMailRequest, resendMailSuccess, resendMailFailed,
  resetPasswordRequest, resetPasswordSuccess, resetPasswordFailed,
  editUserRequest, editUserSuccess, editUserFailed,
  getSingleDataRequest, getSingleDataSuccess, getSingleDataFailed,
  imageUploadRequest, imageUploadSuccess, imageUploadFailed,
  removeImageRequest, removeImageSuccess, removeImageFailed
} from '../actions/userActions';

const defaultState = {
  data: {},
  singleData: {},
  isFetching: false,
  isImageUploaded: false,
  isUploading: false,
  isUsersDataFetched: false,
  isUserSaved: false,
  isUserDeleted: false,
  isResendMail: false,
  isResetUserPassword: false,
  isUserEdit: false,
  isImageRemoved: false,
  isSingleDataFetched: false,
  error: null,
  userSaveError: null,
};

export default handleActions(
  {
    [getDataRequest](state) {
      return {
        ...state,
        isFetching: true,
        isUsersDataFetched: false,
        error: null,
      };
    },
    [getDataSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isUsersDataFetched: true,
        error: null,
      };
    },
    [getDataFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isUsersDataFetched: false,
        error: payload,
      };
    },
    [imageUploadRequest](state) {
      return {
        ...state,
        isImageUploaded: false,
        error: null,
      };
    },
    [imageUploadSuccess](state, { payload }) {
      return {
        ...state,
        imageData: payload,
        isImageUploaded: true,
        error: null,
      };
    },
    [imageUploadFailed](state, { payload }) {
      return {
        ...state,
        isImageUploaded: false,
        error: payload,
      };
    },
    [saveUserRequest](state) {
      return {
        ...state,
        isFetching: true,
        isUserSaved: false,
        userSaveError: null,
      };
    },
    [saveUserSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isUserSaved: true,
        userSaveError: null,
      };
    },
    [saveUserFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isUserSaved: false,
        userSaveError: payload,
      };
    },
    [deleteUserRequest](state) {
      return {
        ...state,
        data: {},
        isFetching: true,
        isUserDeleted: false,
        error: null,
      };
    },
    [deleteUserSuccess](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        data: payload,
        isUserDeleted: true,
        error: null,
      };
    },
    [deleteUserFailed](state, { payload }) {
      return {
        ...state,
        data: {},
        isFetching: false,
        isUserDeleted: false,
        error: payload,
      };
    },
    [resendMailRequest](state) {
      return {
        ...state,
        data: {},
        isFetching: true,
        isResendMail: false,
        error: null,
      };
    },
    [resendMailSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isResendMail: true,
        error: null,
      };
    },
    [resendMailFailed](state, { payload }) {
      return {
        ...state,
        data: {},
        isFetching: false,
        isResendMail: false,
        error: payload,
      };
    },
    [resetPasswordRequest](state) {
      return {
        ...state,
        data: {},
        isFetching: true,
        isResetUserPassword: false,
        error: null,
      };
    },
    [resetPasswordSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isResetUserPassword: true,
        error: null,
      };
    },
    [resetPasswordFailed](state, { payload }) {
      return {
        ...state,
        data: {},
        isFetching: false,
        isResetUserPassword: false,
        error: payload,
      };
    },
    [editUserRequest](state) {
      return {
        ...state,
        isUserSaved: false,
        error: null,
      };
    },
    [editUserSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isUserSaved: true,
        error: null,
      };
    },
    [editUserFailed](state, { payload }) {
      return {
        ...state,
        isUserSaved: false,
        error: payload,
      };
    },
    [getSingleDataRequest](state) {
      return {
        ...state,
        isFetching: true,
        isSingleDataFetched: false,
        error: null,
      };
    },
    [getSingleDataSuccess](state, { payload }) {
      return {
        ...state,
        singleData: payload,
        isFetching: false,
        isSingleDataFetched: true,
        error: null,
      };
    },
    [getSingleDataFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isSingleDataFetched: false,
        error: payload,
      };
    },
    [removeImageRequest](state) {
      return {
        ...state,
        isFetching: true,
        isImageRemoved: false,
        error: null,
      };
    },
    [removeImageSuccess](state, { payload }) {
      return {
        ...state,
        singleData: payload,
        isFetching: false,
        isImageRemoved: true,
        error: null,
      };
    },
    [removeImageFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isImageRemoved: false,
        error: payload,
      };
    },
  },
  defaultState,
);
