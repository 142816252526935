import React from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'reactstrap';
import { Pie, PieChart, ResponsiveContainer } from 'recharts';
import Panel, { PanelTitle } from '../../../shared/components/Panel';

const MostOrderedProducts = ({ children, orderPercentage }) => (
  <div className="dashboard__social-stat-item">
    <div className="dashboard__social-stat-title">
      {children}
    </div>
    <div className="dashboard__social-stat-progress">
      <div className="progress-wrap progress-wrap--small progress-wrap--blue-gradient progress-wrap--rounded">
        <p className="progress__label">{orderPercentage}%</p>
        <Progress value={orderPercentage} />
      </div>
    </div>
  </div>
);

MostOrderedProducts.propTypes = {
  progress: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const WeeklyStat = props => {
  return (
    props.weeklyStats.processedOrders &&
    <Panel
      xl={4}
      lg={7}
      md={12}
      title="Weekly Stats"
    >

      <div className="dashboard__weekly-stat">
        <div className="dashboard__weekly-stat-chart">
          <div className="dashboard__weekly-stat-chart-item">
            <div className="dashboard__weekly-stat-chart-pie">
              <ResponsiveContainer>
                <PieChart className="dashboard__weekly-stat-chart-pie-wrapper">
                  <Pie
                    data={props.weeklyStats.processedOrders}
                    dataKey="value"
                    cx={50}
                    cy={50}
                    innerRadius={50}
                    outerRadius={55}
                  />
                </PieChart>
              </ResponsiveContainer>
              <p className="dashboard__weekly-stat-label" style={{ color: '#b8e986' }}>{props.weeklyStats.processedOrders[0].value}%</p>
            </div>
            <div className="dashboard__weekly-stat-info">
              <p>Processed Orders</p>
            </div>
          </div>
          <div className="dashboard__weekly-stat-chart-item">
            <div className="dashboard__weekly-stat-chart-pie">
              <ResponsiveContainer>
                <PieChart className="dashboard__weekly-stat-chart-pie-wrapper">
                  <Pie
                    data={props.weeklyStats.cancelledOrders}
                    dataKey="value"
                    cx={50}
                    cy={50}
                    innerRadius={50}
                    outerRadius={55}
                  />
                </PieChart>
              </ResponsiveContainer>
              <p className="dashboard__weekly-stat-label" style={{ color: '#ff4861' }}>{props.weeklyStats.cancelledOrders[0].value}%</p>
            </div>
            <div className="dashboard__weekly-stat-info">
              <p>Cancelled Orders</p>
            </div>
          </div>
        </div>
        <hr />
        <PanelTitle title="Most Ordered Products" />
        <div className="dashboard__social-stat">
          {props.weeklyStats.mostOrders.map(item => (
            <MostOrderedProducts key={item.id} orderPercentage={item.orderPercentage}>
              {item.product}
            </MostOrderedProducts>
          ))}
        </div>
      </div>

    </Panel>
  );
};

export default WeeklyStat;
