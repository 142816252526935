import { post } from './base/index';

export default {
  adminLogin: async (email, password) =>
    await post('auth/login', { email: email, password: password }),
  sendResetPasswordLink: async (email) =>
    await post('auth/forgotPassword', { email: email }),
  updatePassword: async (email, password) =>
    await post('auth/reset', { email: email, password: password }),
};
