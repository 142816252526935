import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';
import {
  Card, CardBody, Col, Badge, Table,
} from 'reactstrap';
import CheckIcon from 'mdi-react/CheckIcon';
import BasicTableData from './BasicTableData';
// import { Checkboard } from 'react-color';

const { tableHeaderData, tableRowsData } = BasicTableData();

const BasicTable = (isChecked = false) => {
  const [isCheckedMail, setCheckedMail] = useState(isChecked);
  const { t } = useTranslation('common');

  const onChangeSelect = () => {
    setCheckedMail(!isCheckedMail);
  };

  return (
    <Table>
      <thead>
        <tr>
          <th>
            <label htmlFor="chkAll">
              <input
                id="chkAll"
                className="checkbox-btn__checkbox"
                type="checkbox"
                checked={false}
                onChange={onChangeSelect}
              />
              <span className="checkbox-btn__checkbox-custom">
                <CheckIcon />
              </span>
            </label>
          </th>
          {tableHeaderData.map(item => (
            <th key={item.varientTypeId}>{item.title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableRowsData.map(item => (
          <tr key={item.varientTypeId}>
            <td>
              <label htmlFor={item.varientTypeId} className="checkbox-btn__checkbox-custom">
                <input
                  id={item.varientTypeId}
                  className="checkbox-btn__checkbox"
                  type="checkbox"
                  checked={item.isSelect}
                  onChange={onChangeSelect}
                />
                <span className="checkbox-btn__checkbox-custom">
                  <CheckIcon />
                </span>
              </label>
            </td>
            <td>{item.varientType}</td>
            <td>
              <input
                id={item.varientTypeId}
                type="text"
                value={item.minOrder}
              />
            </td>
            <td>
              <input
                id={item.varientTypeId}
                type="text"
                value={item.skuNumber}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default BasicTable;
