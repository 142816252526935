import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Button,
  ButtonToolbar,
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Badge,
  Table,
} from "reactstrap";
import CheckIcon from "mdi-react/CheckIcon";
import BasicTableData from "./BasicTableData";
import { Field, Form } from "react-final-form";
import renderDropZoneField from "../../../shared/components/form/DropZone";
import { NotificationManager } from "react-notifications";
import { saveProductWithImage } from "../../../redux/actions/productActions";
import renderRadioButtonField from '../../../shared/components/form/RadioButton'
import renderSelectField from '../../../shared/components/form/Select';

const { tableHeaderData, tableRowsData } = BasicTableData();

const ProductSaveForm = ({ onSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [productId, setProductId] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productImage, setProductImage] = useState("");
  const [productNameError, setProductNameError] = useState("");
  // State for product Type

  const [productType, setProductType] = useState(0)
  // state for is liquid or not
  const [isLiquid, setIsLiquid] = useState(false)

  // 
  const [varientTypes, setVarientTypes] = useState([
    {
      isSelect: false,
      varientTypeId: 1,
      varientType: "20KG",
      minimumOrderQty: "50",
      skuNumber: "",
    },
    {
      isSelect: false,
      varientTypeId: 2,
      varientType: "25KG",
      minimumOrderQty: "40",
      skuNumber: "",
    },
    {
      isSelect: false,
      varientTypeId: 3,
      varientType: "0.5MT",
      minimumOrderQty: "1",
      skuNumber: "",
    },
    {
      isSelect: false,
      varientTypeId: 4,
      varientType: "1MT",
      minimumOrderQty: "1",
      skuNumber: "",
    },
  ]);
  const [productSaveClicked, setProductSaveClicked] = useState(false);

  const productsData = useSelector((state) => state.product.data);
  const isFetching = useSelector((state) => state.product.isFetching);
  const isproductSaved = useSelector((state) => state.product.isProductSaved);
  const productError = useSelector((state) => state.product.error);

  const onOptionChange = e => {
    setProductType(e.target.value)
  }

  const onLiquidOptionChange = (event) => {
    setIsLiquid(event.target.value === 'true');
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setProductSaveClicked(true);
    setProductNameError("");
    let isFormValid = true;
    
    if (!productName) {
      isFormValid = false;
      setProductNameError("Product name required");
    }
    
    console.log(varientTypes)
    for (let i = 0; i < varientTypes.length; i++) {
      console.warn(varientTypes[i].isSelect ,"-i selet")
      console.warn(varientTypes[i].skuNumber.trim(),"-i sku")
      if (varientTypes[i].isSelect == true && typeof varientTypes[i].skuNumber === 'string' && varientTypes[i].skuNumber.trim() === '') {
        isFormValid = false
        NotificationManager.error('Please select variant type with SKU Number!', "", 3000);
      }
      
    }

    for (let i = 0; i < varientTypes.length; i++) {
      if (varientTypes[i].isSelect == true && typeof varientTypes[i].minimumOrderQty === 'string' && varientTypes[i].minimumOrderQty.trim() === '' || varientTypes[i].minimumOrderQty === '0') {
        isFormValid = false;
        NotificationManager.error('Minimum Order Quantity is required for selected variant types!', "", 3000);
      }
    }
    
    if (isFormValid) {
      let formData = new FormData();
      formData.append('productId', productId)
      formData.append('productName', productName)
      formData.append('productDescription', productDescription)
      formData.append('productType', productType)
      formData.append('isLiquid', isLiquid)
      formData.append('varientTypes', JSON.stringify(varientTypes))
      if (typeof productImage == 'object') {
        formData.append('file', productImage)
      }
      dispatch(
        saveProductWithImage(formData)
        );
    }
     else {
      setProductSaveClicked(false);
    }
  };
  

  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem("adminInfo"));
    if (adminObj) {
      //TODO load varienttypes
    } else {
      history.push("/log_in");
    }
  }, [dispatch, history]);

  useEffect(() => {
    if (productSaveClicked) {
      if (!isFetching) {
        if (productError) {
          NotificationManager.error(productError.message, "", 3000);
        } else {
          NotificationManager.success(productsData.message, "", 3000);
          history.push("/products");
        }
      }
    }
  }, [history, isFetching, isproductSaved, productError, productSaveClicked, productsData, varientTypes.isSelect, varientTypes.length, varientTypes.skuNumber]);

  const onChangeSelect = (index) => {
    let varients = varientTypes.map((varientType) => {
      return varientType;
    });
    varients[index].isSelect = !varients[index].isSelect;
    setVarientTypes(varients);
  };

  const onChangeMinOrder = (index, value) => {
    const re = /^[0-9\b]+$/;
    if (value === "" || re.test(value)) {
      let varients = varientTypes.map((varientType) => {
        return varientType;
      });
      varients[index].minimumOrderQty = value;
      setVarientTypes(varients);
    }
  };

  const onChangeSKU = (index, value) => {
    let varients = varientTypes.map((varientType) => {
      return varientType;
    });
    varients[index].skuNumber = value;
    setVarientTypes(varients);
  };

  // const onChangeProductType = (index, value) => {
  //   let products = productType.map((productType) => {
  //     return varientType;
  //   });
  //   varients[index].skuNumber = value;
  //   setVarientTypes(varients);
  // };

  const backToProducts = () => {
    history.push("/products");
  };

  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <form
          className="form product-edit"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Container>
            <Row>
              <Col md={12} lg={12} xl={7}>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Product Name</span>
                    <div className="form__form-group-input-wrap">
                      <input
                        name="name"
                        component="input"
                        type="text"
                        value={productName}
                        onChange={(evt) => setProductName(evt.target.value)}
                      />
                      {productNameError && (
                        <span className="form__form-group-error">
                          {productNameError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Description</span>
                    <div className="form__form-group-field">
                      <textarea
                        name="name"
                        component="textarea"
                        type="textarea"
                        value={productDescription}
                        onChange={(evt) =>
                          setProductDescription(evt.target.value)
                        }
                      />
                    </div>
                  </div>

                  <div>
                    <Table>
                      <thead>
                        <tr>
                          <th>
                            <label htmlFor="chkAll">
                              {/* <input
                                id="chkAll"
                                className="checkbox-btn__checkbox"
                                type="checkbox"
                                checked={false}
                                onChange={onChangeSelect}
                              /> 
                              <span className="checkbox-btn__checkbox-custom">
                                <CheckIcon />
                              </span>*/}
                            </label>
                          </th>
                          {tableHeaderData.map((item) => (
                            <th align="center" key={item.varientTypeId}>
                              {item.title}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {varientTypes.map((item, index) => (
                          <tr key={item.varientTypeId}>
                            <td>
                              <label
                                htmlFor={item.varientTypeId}
                                className="checkbox-btn__checkbox-custom"
                              >
                                <input
                                  id={item.varientTypeId}
                                  className="checkbox-btn__checkbox"
                                  type="checkbox"
                                  checked={item.isSelect}
                                  onChange={() => onChangeSelect(index)}
                                />
                                <span className="checkbox-btn__checkbox-custom">
                                  <CheckIcon />
                                </span>
                              </label>
                            </td>
                            <td>{item.varientType}</td>
                            <td>
                              <input
                                id={item.varientTypeId}
                                type="text"
                                maxlength="4"
                                value={item.minimumOrderQty}
                                onChange={(evt) =>
                                  onChangeMinOrder(index, evt.target.value)

                                }
                              />
                            </td>
                            <td>
                              <input
                                id={item.varientTypeId}
                                type="text"
                                value={item.skuNumber}
                                onChange={(evt) =>
                                  onChangeSKU(index, evt.target.value)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </Col>
              <Col md={12} lg={12} xl={4}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Upload photo</span>
                  <div className="form__form-group-field">
                    <Field
                      name="files"
                      component={renderDropZoneField}
                      setPicture={setProductImage}
                    />
                  </div>
                </div>

                {/* Radio Button */}
                <div className="form__form-group">
                  <span className="form__form-group-label">Select Product Type</span>
                  <div className="form__form-group-field mt-3">
                    <input
                      type="radio"
                      name="productType"
                      value="0"
                      id="normal"
                      checked={productType == 0}
                      onChange={onOptionChange}
                      style={{
                        height: "15px",
                        width: "15px"
                      }}
                    />
                    <label htmlFor="normal" className="ml-1">Normal</label>

                    <input
                      className="ml-3"
                      type="radio"
                      name="productType"
                      value="1"
                      id="silo"
                      checked={productType == 1}
                      onChange={onOptionChange}
                      style={{
                        height: "15px",
                        width: "15px"
                      }}
                    />
                    <label htmlFor="silo" className="ml-1">Silo</label>

                    <input
                      className="ml-3"
                      type="radio"
                      name="productType"
                      value="2"
                      id="bulk"
                      // class="radioButton"
                      checked={productType == 2}
                      onChange={onOptionChange}
                      style={{
                        height: "15px",
                        width: "15px"
                      }}
                    />
                    <label htmlFor="bulk" className="ml-1">Bulk</label>
                  </div>
                </div>

                <div className="form__form-group">
                  <span className="form__form-group-label">Is liquid</span>
                  <div className="form__form-group-field mt-3">
                    <input
                      type="radio"
                      name="isLiquid"
                      value={true}
                      id="yes"
                      checked={isLiquid === true}
                      onChange={onLiquidOptionChange}
                      style={{
                        height: "15px",
                        width: "15px"
                      }}
                    />
                    <label htmlFor="yes" className="ml-1">Yes</label>

                    <input
                      className="ml-3"
                      type="radio"
                      name="isLiquid"
                      value={false}
                      id="no"
                      checked={isLiquid === false}
                      onChange={onLiquidOptionChange}
                      style={{
                        height: "15px",
                        width: "15px"
                      }}
                    />
                    <label htmlFor="no" className="ml-1">No</label>
                  </div>
                </div>

                {/*  */}
              </Col>

            </Row>




            <Row>
              <Col md={12} lg={12} xl={7}></Col>
              <Col md={12} lg={12} xl={4}>
                <div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button type="button" onClick={backToProducts}>
                      Cancel
                    </Button>
                    {/* <Button type="button" onClick={form.reset}>Cancel</Button> */}
                  </ButtonToolbar>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </Form>
  );
};

ProductSaveForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ProductSaveForm;
