import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
import Dashboard from './Dashboard';
import Orders from './Orders';
import OrderDetails from './OrderDetails';
import Products from './Products';
import ProductDetails from './ProductDetails'
import SaveProduct from './ProductSave';
import EditProduct from './ProductEdit';
import Users from './Users';
import SaveUser from './UserSave';
import UserDetails from './UserDetails';
import EditUser from './UserEdit';
import Promotions from './Promotions';
import PromotionSend from './PromotionSend';
import ChangePassword from './ChangePassword';

export default () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/orders" component={Orders} />
      <Route path="/viewOrder" component={OrderDetails} />
      <Route path="/products" component={Products} />
      <Route path="/saveProduct" component={SaveProduct} />
      <Route path="/editProduct" component={EditProduct} />
      <Route path="/users" component={Users} />
      <Route path="/saveUser" component={SaveUser} />
      <Route path="/viewUser" component={UserDetails} />
      <Route path="/editUser" component={EditUser} />
      <Route path="/promotions" component={Promotions} />
      <Route path="/newPromotion" component={PromotionSend} />
      <Route path="/viewProduct" component={ProductDetails} />
      <Route path="/changepassword" component={ChangePassword} />
    </div>
  </div>
);
