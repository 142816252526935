import React from "react";
import { Row, Col, Breadcrumb, BreadcrumbItem, Container } from "reactstrap";
import { Link } from "react-router-dom";

const BreadCrumb = ({ title, pageTitle, url }) => {
    return (
        <React.Fragment>
            <Row>
                <Col xs={12}>
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <Container fluid>
                            <Row>
                                <Col xxl={8} xl={8} lg={8} md={6} xs={6} sm={6}>
                                    <h4 className="page-title">{title}</h4>
                                </Col>
                                {/* <Col xxl={4} xl={4} lg={4} md={6} xs={6} sm={6}>
                                    <Breadcrumb className="text-end">
                                        <BreadcrumbItem>
                                            <Link to={`${url}`}>
                                                <b className="text-primary">{pageTitle}</b>
                                            </Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>{title}</BreadcrumbItem>
                                    </Breadcrumb>
                                </Col> */}
                            </Row>
                        </Container>


                        
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default BreadCrumb;
