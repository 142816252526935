import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import PromotionSendCard from './components/PromotionSendCard';
import BreadCrumb from '../../shared/components/BreadCrumb';
const PromotionSend = () => (
  <Container>
    <Row>
      <Col md={12}>
        <BreadCrumb title="New Promotion"/>
      </Col>
    </Row>
    <Row>
      <PromotionSendCard />
    </Row>
  </Container>
);

export default PromotionSend;
