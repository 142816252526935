import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { updatePassword } from '../../../redux/actions/authenticationActions';
import { Button } from 'reactstrap';
require('dotenv').config();
const jwt = require('jsonwebtoken');

const NewPasswordForm = ({
  onSubmit, fieldUser,
}) => {

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const dispatch = useDispatch();
  const isUpdatePasswordError = useSelector(state => state.authentication.error);
  const isPasswordUpdated = useSelector(state => state.authentication.isPasswordUpdated);
  const history = useHistory();

  
    useEffect(() => {

    const currentUrl = window.location.href;
    var query = currentUrl.substring(currentUrl.indexOf("new_password/") + 13);
    if (query) {
      try {
        const decoded = (jwt.verify)(query, process.env.REACT_APP_JWT_SECRET);
        if (decoded.email) {
          setEmail(decoded.email);
        }
        else {
          alert("The reset link is invalid");
          history.push('/log_in');
        }
      }
      catch (ex) {
        //(ex.message);
        alert("The reset link invalid or expired");
        history.push('/log_in');
        //history.push('/log_in');
      }
    }
    else {
      alert("The Reset password link is invalid.")
    }

  }, []);

  useEffect(() => {
    // alert(loginError)
    if (isPasswordUpdated) {
      alert("Password Reset Successfully.");
      history.push('/log_in')
    }
    else if (isUpdatePasswordError) {
      alert("Password reset failed.")
      history.push('/reset_password')
    }
  }, [isPasswordUpdated, isUpdatePasswordError]);

  const handleSubmit = () => {
    setPasswordError('');
    setConfirmPasswordError('');
    if (!password) {
      setPasswordError("Password Required")
    }
    else if (!confirmPassword) {
      setConfirmPasswordError("Password Required")
    }
    else if (password != confirmPassword) {
      setConfirmPasswordError("Password and Confirm Password should be same")
    }

    else {
      dispatch(updatePassword(email, password));
    }
  }

  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <form className="form reset-password-form" onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
          <div className="form__form-group">
            <div>
              <span className="form__form-group-label">{fieldUser}</span>
            </div>

            <div className="form__form-group">
              {/* <span className="form__form-group-label">Password</span> */}
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <KeyVariantIcon />
                </div>
                <div class="form__form-group-input-wrap">
                  <input
                    name="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={(evt) => setPassword(evt.target.value)}
                  />
                  {passwordError && <span className="form__form-group-error">{passwordError}</span>}
                </div>
              </div>
            </div>
            <div className="form__form-group">
              {/* <span className="form__form-group-label">Confirm Password</span> */}
              <div className="form__form-group-field">
                <div className="form__form-group-icon">
                  <KeyVariantIcon />
                </div>
                <div class="form__form-group-input-wrap">
                  <input
                    name="password"
                    type="password"
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(evt) => setConfirmPassword(evt.target.value)}
                  />
                  {confirmPasswordError && <span className="form__form-group-error">{confirmPasswordError}</span>}
                </div>
              </div>
            </div>
          </div>
          <Button className="account__btn" color="primary">
            Reset Password
          </Button>
        </form>
      )}
    </Form>);
};

NewPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldUser: PropTypes.shape(),
};

NewPasswordForm.defaultProps = {
  fieldUser: null,
};

export default NewPasswordForm;
