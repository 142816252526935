import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ProductEditCard from './components/ProductEditCard';
import BreadCrumb from '../../shared/components/BreadCrumb';

const ProductEdit = () => (
  <Container>
    <Row>
      <Col md={12}>
        <BreadCrumb title="Edit Product"/>
      </Col>
    </Row>
    <Row>
      <ProductEditCard />
    </Row>
  </Container>
);

export default ProductEdit;
