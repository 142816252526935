import appConfigApi from './api/appConfigApi';
import authenticationApi from './api/authenticationApi';
import dashboardApi from './api/dashboardApi';
import usersApi from './api/usersApi';
import productsApi from './api/productsApi';
import ordersApi from './api/ordersApi';
import promotionsApi from './api/promotionsApi';

const api = {
  appConfig: appConfigApi,
  authentication: authenticationApi,
  users: usersApi,
  products: productsApi,
  orders: ordersApi,
  promotion: promotionsApi,
};

export default api;
