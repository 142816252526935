import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, ButtonToolbar, Col, Container, Row, Alert } from "reactstrap";
import { Field, Form } from "react-final-form";
import { connect, useDispatch, useSelector } from "react-redux";
import renderDropZoneField from "../../../shared/components/form/DropZone";
import { NotificationManager } from 'react-notifications';
import { saveUserWithImage } from "../../../redux/actions/userActions";

const UserSaveForm = ({ onSubmit, errorMessage, errorMsg }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [companyImage, setCompanyImage] = useState("")
  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [accountNoError, setAccountNoError] = useState("");
  const [userSaveClicked, setUserSaveClicked] = useState(false);

  const isFetching = useSelector((state) => state.users.isFetching);
  const userSaveErrorData = useSelector((state) => state.users.userSaveError);
  const isUserSaved = useSelector((state) => state.users.isUserSaved);
  const userData = useSelector((state) => state.users.data);
  const history = useHistory();

  console.log(isUserSaved)

  const handleSubmit = (e) => {
    e.preventDefault();
    setUserSaveClicked(true);
    setEmailError("");
    setNameError("");
    let isFormValid = true;
    if (!email) {
      isFormValid = false;
      setEmailError("Email required");
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      isFormValid = false;
      setEmailError("Enter valid email");
    }
    if (!name) {
      isFormValid = false;
      setNameError("User name required");
    }
    if (!phoneNo) {
      isFormValid = false;
      setPhoneNoError("Phone number required");
    }
    if (!accountNo) {
      isFormValid = false;
      setAccountNoError("Account number required");
    }
    if (isFormValid) {
      let formData = new FormData();
      formData.append('email', email)
      formData.append('name', name)
      formData.append('companyName', companyName)
      formData.append('companyAddress', companyAddress)
      formData.append('phoneNo', phoneNo)
      formData.append('accountNo', accountNo)

      if (typeof companyImage == 'object') {
        formData.append('file', companyImage)
        console.log(formData.get('file'))
      }
      dispatch(
        saveUserWithImage(formData)
      );
    }
    else {
      setUserSaveClicked(false);
    }
  };

  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem('adminInfo'));
    if (adminObj) {

    }
    else {
      history.push("/log_in")
    }

  }, [dispatch, history]);

  console.log(userSaveErrorData)
  useEffect(() => {
    if (userSaveClicked) {
      if (!isFetching) {
        console.log(userSaveErrorData)
        if (userSaveErrorData) {
          NotificationManager.error(userSaveErrorData.message, '', 3000);
        }
        else {
          NotificationManager.success('User created succcessfully', '', 3000);
          history.push('/users');
        }
      }
    }
  }, [isFetching,
    userSaveClicked,
    userSaveErrorData,
    userData,
    history,
  ]);

  const backToUserTable = () => {
    history.push('/users');
  };

  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <form
          className="form product-edit"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Alert color="danger" isOpen={!!errorMessage || !!errorMsg}>
            {errorMessage}
            {errorMsg}
          </Alert>
          <Container>
            <Row>
              <Col md={12} lg={12} xl={6}>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">User Name</span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={70}
                        name="name"
                        component="input"
                        type="text"
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                      />
                      {nameError && (
                        <span className="form__form-group-error">
                          {nameError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email</span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={100}
                        name="email"
                        component="input"
                        type="text"
                        value={email}
                        onChange={(evt) => setEmail(evt.target.value)}
                      />
                      {emailError && (
                        <span className="form__form-group-error">
                          {emailError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Company Name</span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={100}
                        name="company"
                        component="input"
                        type="text"
                        value={companyName}
                        onChange={(evt) => setCompanyName(evt.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Company Address
                    </span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={200}
                        name="company"
                        component="input"
                        type="text"
                        value={companyAddress}
                        onChange={(evt) => setCompanyAddress(evt.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Account Number</span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={20}
                        name="account"
                        component="input"
                        type="text"
                        value={accountNo}
                        onChange={(evt) => setAccountNo(evt.target.value)}
                      />
                      {accountNoError && (
                        <span className="form__form-group-error">
                          {accountNoError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Phone Number</span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={20}
                        name="phone"
                        component="input"
                        type="text"
                        value={phoneNo}
                        onChange={(evt) => setPhoneNo(+evt.target.value === +evt.target.value ? evt.target.value : phoneNo)}
                      />
                      {phoneNoError && (
                        <span className="form__form-group-error">
                          {phoneNoError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12} lg={12} xl={4}>
                <div className="form__form-group">
                  <span className="form__form-group-label">Upload photo</span>
                  <div className="form__form-group-field">
                    <Field
                      name="files"
                      component={renderDropZoneField}
                      setPicture={setCompanyImage}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} xl={7}></Col>
              <Col md={6} lg={6} xl={4}>
                <div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button color="primary" onClick={handleSubmit}>
                      Save
                    </Button>
                    <Button type="button" onClick={backToUserTable}>Cancel</Button>
                  </ButtonToolbar>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </Form>
  );
};

UserSaveForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  form: PropTypes.string.isRequired,
};

export default connect((state) => ({
  errorMsg: state.user.error,
}))(UserSaveForm);
