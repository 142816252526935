import React, {useState} from 'react';
import DeleteUserForm from './components/deleteUserForm';
import VerifyOTPForm from './components/VerifyDeleteOtpForm';
import DeleteConfirmForm from './components/deleteConfirmForm';

const DeleteAccount = () => {
const [isOtpTriggered, setOtpTrigger] = useState(false)
const [otpVerified, setOtpVerified] = useState(false)
const userToken = localStorage.getItem("token")

return (
        <div className="account account--not-photo">
            <div className="account__wrapper">
                <div className="account__card">
                    {!isOtpTriggered && !userToken && <DeleteUserForm setOtpTrigger = {setOtpTrigger}/>}
                    {!otpVerified && userToken && <VerifyOTPForm setOtpVerified={setOtpVerified} />}
                    {otpVerified && userToken && <DeleteConfirmForm setOtpVerified={setOtpVerified} setOtpTrigger = {setOtpTrigger}/>}
                </div>
            </div>
        </div>
    )
}

export default DeleteAccount;