import { createAction } from 'redux-actions';
import usersApi from '../../utils/api/usersApi';
require('dotenv').config();

export const getDataRequest = createAction('FETCH_USERS_REQUEST');
export const getDataSuccess = createAction('FETCH_USERS_SUCCESS');
export const getDataFailed = createAction('FETCH_USERS_FAILED');

export const imageUploadRequest = createAction('IMAGE_UPLOAD_REQUEST');
export const imageUploadSuccess = createAction('IMAGE_UPLOAD_SUCCESS');
export const imageUploadFailed = createAction('IMAGE_UPLOAD_FAILED');

export const saveUserRequest = createAction('SAVE_USER_REQUEST');
export const saveUserSuccess = createAction('SAVE_USER_SUCCESS');
export const saveUserFailed = createAction('SAVE_USER_FAILED');

export const deleteUserRequest = createAction('DELETE_USER_REQUEST');
export const deleteUserSuccess = createAction('DELETE_USER_SUCCESS');
export const deleteUserFailed = createAction('DELETE_USER_FAILED');

export const resendMailRequest = createAction('RESEND_MAIL_REQUEST');
export const resendMailSuccess = createAction('RESEND_MAIL_SUCCESS');
export const resendMailFailed = createAction('RESEND_MAIL_FAILED');

export const resetPasswordRequest = createAction('RESET_PASSWORD_REQUEST');
export const resetPasswordSuccess = createAction('RESET_PASSWORD_SUCCESS');
export const resetPasswordFailed = createAction('RESET_PASSWORD_FAILED');

export const editUserRequest = createAction('EDIT_USER_REQUEST');
export const editUserSuccess = createAction('EDIT_USER_SUCCESS');
export const editUserFailed = createAction('EDIT_USER_SUCCESS');

export const getSingleDataRequest = createAction('FETCH_SINGLE_USER_REQUEST');
export const getSingleDataSuccess = createAction('FETCH_SINGLE_USER_SUCCESS')
export const getSingleDataFailed = createAction('FETCH_SINGLE_USER_FAILED')

export const removeImageRequest = createAction('FETCH_IMAGE_REMOVE_REQUEST')
export const removeImageSuccess = createAction('FETCH_IMAGE_REMOVE_SUCCESS')
export const removeImageFailed = createAction('FETCH_IMAAGE_REMOVE_FAILED')

export const getAllUsers = (searchText, sortName, sortOrder, currentPage, pageLimit) => async (dispatch) => {
  searchText = searchText ? searchText : '';
  try {
    let param = '?sortOrder=' + sortOrder +
      "&sortName=" + sortName +
      "&currentPage=" + currentPage +
      "&pageLimit=" + pageLimit +
      "&keyword=" + searchText;
    dispatch(getDataRequest());
    const results = await usersApi.getAllUsers(param);
    //console.log(results)
    if (results && results.error) {
      dispatch(getDataFailed(results.error));
    }
    else if (results.data) {
      dispatch(getDataSuccess(results.data));
    }
    else {
      dispatch(getDataFailed(results));
    }
  } catch (e) {
    dispatch(getDataFailed(e));
  }
};

export const saveUserWithImage = (userDetails) => async (dispatch) => {
  try {
    dispatch(saveUserRequest());
    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'));
    await fetch(process.env.REACT_APP_BASE_URL + 'users/saveUserWithImage', {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + adminInfo.token
      },
      body: userDetails,
    }).then((response) => {
      let resp = response.json()
      console.log(resp)
      resp.then((data) => {
        console.log(data,"in action")
        if (data.errorCode > 0) {
          console.log(data.errorCode,"error code in action")
          dispatch(saveUserFailed(data));
        }
        else {
          dispatch(saveUserSuccess(data));
        }
      })
      if (response === undefined) {
        console.log("******")
        dispatch(saveUserFailed({}));
      }
    }).catch((ex) => {
      console.log("??????")
      dispatch(saveUserFailed({}));
    })

    // console.log(results.data.user)
    // if (results && results.error) {
    //   dispatch(imageUploadFailed(results.error));
    // }
    // else if (results && results.data && results.data.user) {
    //   dispatch(imageUploadSuccess(results.data));
    // }
    // else {
    //   dispatch(imageUploadFailed(results.error));
    // }
  } catch (e) {
    console.log(e)
    dispatch(imageUploadFailed(e));
  }
};

export const saveUser = (email, name, companyName, companyAddress, phoneNo, accountNo, companyImage) => async (dispatch) => {
  try {
    console.log(companyImage)
    dispatch(saveUserRequest());
    const results = await usersApi.saveUser(email, name, companyName, companyAddress, phoneNo, accountNo, companyImage);
    console.log(results.data.user)
    if (results && results.error) {
      dispatch(saveUserFailed(results.error));
    }
    else if (results && results.data && results.data.user) {
      dispatch(saveUserSuccess(results.data.user));
    }
    else {
      dispatch(saveUserFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(saveUserFailed(e));
  }
};

export const resendMail = (userId) => async (dispatch) => {
  try {
    dispatch(resendMailRequest());
    const results = await usersApi.resendMail(userId);
    console.log(results)
    if (results && results.error) {
      dispatch(resendMailFailed(results.error));
    }
    else if (results) {
      dispatch(resendMailSuccess(results));
    }
    else {
      dispatch(resendMailFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(resendMailFailed(e));
  }
};

export const deleteUser = (userId) => async (dispatch) => {
  try {
    dispatch(deleteUserRequest());
    const results = await usersApi.deleteUser(userId);
    console.log(results)
    if (results && results.error) {
      dispatch(deleteUserFailed(results.error));
    }
    else if (results) {
      dispatch(deleteUserSuccess(results));
    }
    else {
      dispatch(deleteUserFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(deleteUserFailed(e));
  }
};

export const resetUserPassword = (userId) => async (dispatch) => {
  try {
    dispatch(resetPasswordRequest());
    const results = await usersApi.resetPassword(userId);
    console.log(results)
    if (results && results.error) {
      dispatch(resetPasswordFailed(results.error));
    }
    else {
      dispatch(resetPasswordSuccess(results));
    }
  } catch (e) {
    console.log(e)
    dispatch(resetPasswordFailed(e));
  }
};

export const editUser = (userDetails) => async (dispatch) => {
  try {
    dispatch(editUserRequest());
    const adminInfo = JSON.parse(localStorage.getItem('adminInfo'));
    await fetch(process.env.REACT_APP_BASE_URL + `users/updateUserWithImage`, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + adminInfo.token
      },
      body: userDetails,
    }).then((response) => {
      let resp = response.json()
      resp.then((data) => {
        console.log(data)
        if (data.errorCode > 0) {
          dispatch(editUserFailed(data));
        }
        else {
          dispatch(editUserSuccess(data));
        }
      })
      if (response === undefined) {
        dispatch(editUserFailed({}));
      }
    }).catch((ex) => {
      dispatch(editUserFailed({}));
    })
  } catch (e) {
    console.log(e)
    dispatch(editUserFailed(e));
  }
};

export const singleUser = (userId) => async (dispatch) => {
  try {
    dispatch(getSingleDataRequest());
    const results = await usersApi.getSingleUser(userId);
    console.log(results)
    if (results && results.error) {
      dispatch(getSingleDataFailed(results.error));
    }
    else if (results && results.data) {
      dispatch(getSingleDataSuccess(results.data));
    }
    else {
      dispatch(getSingleDataFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(getSingleDataFailed(e));
  }
};

export const removeImage = (userId) => async (dispatch) => {
  try {
    dispatch(removeImageRequest());
    const results = await usersApi.removeImage(userId);
    console.log(results)
    if (results && results.error) {
      dispatch(removeImageFailed(results.error));
    }
    else if (results) {
      dispatch(removeImageSuccess(results));
    }
    else {
      dispatch(removeImageFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(removeImageFailed(e));
  }
};