import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import {
  ButtonToolbar, Badge, Card, CardBody, Col,
}
  from 'reactstrap';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon';
import CancelIcon from 'mdi-react/CancelIcon';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import PromotionsTableHead from './PromotionsTableHead';
import { getAllPromotions } from '../../../redux/actions/promotionsActions'

const PromotionsTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isFetching = useSelector((state) => state.promotion.isFetching);
  const promotionsData = useSelector((state) => state.promotion.data);
  

  const [searchText, setSearchText] = useState("");
  const [sortName, setSortName] = useState("createdAt");
  const [sortOrder, setSortOrder] = useState("desc");
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);

  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem("adminInfo"));
    if (adminObj) {
      dispatch(
        getAllPromotions(searchText, sortName, sortOrder, page, rowsPerPage)
      );
    } else {
      history.push("/log_in");
    }
  }, []);

  useEffect(() => {
    dispatch(
      getAllPromotions(searchText, sortName, sortOrder, page, rowsPerPage)
    );
  }, [searchText, sortName, sortOrder, page, rowsPerPage]);

   useEffect(() => {
     if (
      promotionsData 
     ) {
       setData(promotionsData);
       console.log(promotionsData)
     }
   }, [promotionsData]);

  const handleRequestSort = (event, property) => {
  
    let orderTemp = "desc";
    if (sortName === property && sortOrder === "desc") {
      orderTemp = "asc";
    }
    setSortName(property);
    setSortOrder(orderTemp);
  };

  const handleSearch = () => {
    // dispatch(getAllUsers(searchText, sortName, sortOrder, page, rowsPerPage));
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      const newSelected = new Map();
      promotionsData.map(n => newSelected.set(n.id, true));
      setSelected(newSelected);
      return;
    }
    setSelected(new Map([]));
  };

  const handleClick = (event, id) => {
    const newSelected = new Map(selected);
    const value = newSelected.get(id);
    let isActive = true;
    if (value) {
      isActive = false;
    }
    newSelected.set(id, isActive);
    setSelected(newSelected);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleDeleteSelected = () => {
    let copyData = [...data];
    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }
    setData(copyData);
    setSelected(new Map([]));
  };


  const handleOnViewClick = () => {

  };

  const isSelected = id => !!selected.get(id);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="card__title">
            <div className="inbox__emails-control-search">
            <input
                placeholder="Search"
                name="search"
                type="text"
                value={searchText}
                onChange={(evt) => setSearchText(evt.target.value)}
              />
              <div
                className="inbox__emails-control-search-icon"
                onClick={handleSearch}><MagnifyIcon /></div>
            </div>
            <ButtonToolbar className="products-list__btn-toolbar-top">
              <Link className="btn btn-primary products-list__btn-add" to="/newPromotion">New Promotion</Link>
            </ButtonToolbar>
            {/* <h5 className="bold-text">Orders List</h5> */}
          </div>
          {/* <MatTableToolbar
            numSelected={[...selected].filter(el => el[1]).length}
            handleDeleteSelected={handleDeleteSelected}
            onRequestSort={handleRequestSort}
          /> */}
          {promotionsData.promotions && promotionsData.promotions.length > 0 ? (
            <div>
          <div className="material-table__wrap">
            <Table className="material-table">
              <PromotionsTableHead
                numSelected={[...selected].filter(el => el[1]).length}
                order={sortOrder}
                orderBy={sortName}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={promotionsData.promotions.length}
              />
              <TableBody>
                {promotionsData.promotions.map((d) => {
                    const select = isSelected(d.id);
                    return (
                      <TableRow
                      className="material-table__row"
                      aria-checked={select}
                      tabIndex={-1}
                      key={d.id}
                      selected={select}
                      >
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                          component="th"
                          scope="row"
                          padding="none"
                        >
                          {d.title}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >{d.message}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >{d.date}
                        </TableCell>
                        <TableCell
                          className="material-table__cell material-table__cell-right"
                        >{d.deliveryType}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 2 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            className="material-table__pagination"
            count={promotionsData.total[0].total}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={[5, 10, 15]}
            dir="ltr"
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
          />
          </div>
          ) : (
            <div>No data found</div>
          )}
        </CardBody>
      </Card>
    </Col>
  );
};

export default PromotionsTable;
