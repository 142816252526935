import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Button, ButtonToolbar, Col, Container, Row, Alert } from "reactstrap";
import { Field, Form } from "react-final-form";
import { connect, useDispatch, useSelector } from "react-redux";
import renderDropZoneField from "../../../shared/components/form/DropZone";
import { NotificationManager } from "react-notifications";
import { singleUser, editUser } from "../../../redux/actions/userActions";

const UserEditForm = ({ onSubmit, errorMessage, errorMsg }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [accountNo, setAccountNo] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [companyImage, setCompanyImage] = useState("");
  const [nameError, setNameError] = useState("");
  const [phoneNoError, setPhoneNoError] = useState("");
  const [accountNoError, setAccountNoError] = useState("");
  const [singleUserData, setSingleUserData] = useState("");
  const [userId, setUserId] = useState();
  const [userEditClicked, setUserEditClicked] = useState(false);
  const [isImageUpdated, setIsImageUpdated] = useState(false);
  const [currentImagePath, setCurrentImagePath] = useState("");
  const [showImageUpload, setShowImageUpload] = useState(false);

  console.log(userId);
  const isUserSaved = useSelector((state) => state.users.isUserSaved);
  const userEditError = useSelector((state) => state.users.error);
  const singleUserDetails = useSelector((state) => state.users.singleData);
  const userData = useSelector((state) => state.users.data);
  const history = useHistory();
  const location = useLocation();

  const selectedUserId = location.state.userId;

  const handleSubmit = (selectedUserId) => {
    console.log(selectedUserId);
    setUserId(selectedUserId);
    setUserEditClicked(true);
    setNameError("");
    let isFormValid = true;
    if (!name) {
      isFormValid = false;
      setNameError("User name required");
    }
    if (!phoneNo) {
      isFormValid = false;
      setPhoneNoError("Phone number required");
    }
    if (!accountNo) {
      isFormValid = false;
      setAccountNoError("Account number required");
    }
    if (isFormValid) {
      let formData = new FormData();
      formData.append("userId", selectedUserId);
      formData.append("email", email);
      formData.append("name", name);
      formData.append("companyName", companyName);
      formData.append("companyAddress", companyAddress);
      formData.append("phoneNo", phoneNo);
      formData.append("accountNo", accountNo);
      formData.append("currentImagePath", currentImagePath);
      formData.append("isImageUpdated", isImageUpdated);
      if (typeof companyImage == "object") {
        formData.append("file", companyImage);
        console.log(formData.get("file"));
      }
      dispatch(editUser(formData));
    }
  };

  // const remove = (selectedUserId) => {
  //   setUserId(selectedUserId)
  //   dispatch(removeImage(selectedUserId));
  // };

  const removeImage = () => {
    setShowImageUpload(true);
    setCurrentImagePath("");
  };
  useEffect(() => {
    setUserId(selectedUserId);
    let adminObj = JSON.parse(localStorage.getItem("adminInfo"));
    if (adminObj) {
      dispatch(singleUser(selectedUserId));
    } else {
      history.push("/log_in");
    }
  }, [dispatch, history, selectedUserId]);

  useEffect(() => {
    console.log(singleUserDetails);
    if (
      singleUserDetails &&
      singleUserDetails.user &&
      singleUserDetails.user.length > 0
    ) {
      console.log(singleUserDetails);
      setSingleUserData(singleUserDetails);
      singleUserDetails.user.map((d) => {
        setEmail(d.email);
        setName(d.name);
        setCompanyName(d.companyName);
        setCompanyAddress(d.companyAddress);
        setAccountNo(d.accountNo);
        setPhoneNo(d.phoneNo);
        setCompanyImage(d.companyImage.toString("base64"));
        setCurrentImagePath(d.companyImage);
        setUserId(selectedUserId);
        setShowImageUpload(d.companyImage ? false : true);
      });
    }
  }, [singleUserDetails]);

  useEffect(() => {
    if (userEditClicked) {
      if (userEditError) {
        NotificationManager.error(userEditError.message, "", 3000);
      } else {
        // NotificationManager.success(userData.message, "", 3000);
        NotificationManager.success("User updated sucessfully", "", 3000);
        history.push("/users");
      }
    }
  }, [userEditClicked, userEditError, isUserSaved, userData, history]);

  const backToUserTable = () => {
    history.push("/users");
  };

  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <form
          className="form product-edit"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Alert color="danger" isOpen={!!errorMessage || !!errorMsg}>
            {errorMessage}
            {errorMsg}
          </Alert>
          <Container>
            <Row>
              <Col md={12} lg={12} xl={6}>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">User Name</span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={100}
                        id={name}
                        type="text"
                        value={name}
                        onChange={(evt) => setName(evt.target.value)}
                      />
                      {nameError && (
                        <span className="form__form-group-error">
                          {nameError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Email</span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={100}
                        id={email}
                        type="text"
                        value={email}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Company Name</span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={100}
                        id={companyName}
                        type="text"
                        value={companyName}
                        onChange={(evt) => setCompanyName(evt.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Company Address
                    </span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={200}
                        id={companyAddress}
                        type="text"
                        value={companyAddress}
                        onChange={(evt) => setCompanyAddress(evt.target.value)}
                      />
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">
                      Account Number
                    </span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={20}
                        id={accountNo}
                        type="text"
                        value={accountNo}
                        onChange={(evt) => setAccountNo(evt.target.value)}
                      />
                      {accountNoError && (
                        <span className="form__form-group-error">
                          {accountNoError}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="form__form-group">
                    <span className="form__form-group-label">Phone Number</span>
                    <div className="form__form-group-input-wrap">
                      <input
                        maxLength={20}
                        id={phoneNo}
                        type="text"
                        value={phoneNo}
                        onChange={(evt) =>
                          setPhoneNo(
                            +evt.target.value === +evt.target.value
                              ? evt.target.value
                              : phoneNo
                          )
                        }
                      />
                      {phoneNoError && (
                        <span className="form__form-group-error">
                          {phoneNoError}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={12} lg={12} xl={4}>
                {currentImagePath && (
                  <div className="product-gallery">
                    <img
                      src={currentImagePath.toString("base64")}
                      alt="company-img"
                    />
                    <Button
                      type="button"
                      //onClick={() => remove(selectedUserId)}
                      onClick={removeImage}
                    >
                      Remove
                    </Button>
                  </div>
                )}
                {showImageUpload && (
                  <div className="form__form-group">
                    <span className="form__form-group-label">Upload photo</span>
                    <div className="form__form-group-field">
                      <Field
                        name="files"
                        component={renderDropZoneField}
                        setPicture={setCompanyImage}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={12} xl={7}></Col>
              <Col md={6} lg={6} xl={4}>
                <div>
                  <ButtonToolbar className="form__button-toolbar">
                    <Button
                      color="primary"
                      onClick={() => handleSubmit(selectedUserId)}
                    >
                      Save
                    </Button>
                    <Button type="button" onClick={backToUserTable}>
                      Cancel
                    </Button>
                  </ButtonToolbar>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </Form>
  );
};

UserEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  //form: PropTypes.string.isRequired,
};

export default connect((state) => ({
  errorMsg: state.user.error,
}))(UserEditForm);
