import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import FormField from '../form/FormField';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import PasswordField from '../../../shared/components/form/Password';
import { adminLogin } from '../../../redux/actions/authenticationActions';
import { NotificationManager } from 'react-notifications';
const bhlFeedsLogo = `${process.env.PUBLIC_URL}/img/bhl_logo.JPG`;

const LogInForm = ({
  onSubmit, errorMessage, errorMsg, typeFieldUser, form,
}) => {

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [signinClicked, setSigninClicked] = useState(false);

  const isFetching = useSelector(state => state.authentication.isFetching);
  const loginData = useSelector(state => state.authentication.data);
  const loginError = useSelector(state => state.authentication.error);
  const isSignedIn = useSelector(state => state.authentication.isSignedIn);
  const history = useHistory();

  const handleSubmit = () => {
    setEmailError('');
    setPasswordError('');
    let isFormValid = true;
    if (!email) {
      isFormValid = false;
      setEmailError("Email required")
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      isFormValid = false;
      setEmailError("Enter valid email")
    }
    if (!password) {
      isFormValid = false;
      setPasswordError("Password required")
    }
    if (isFormValid) {
      setSigninClicked(true)
      dispatch(adminLogin(email, password));
    }
    // setSigninClicked(false);
  }

  useEffect(() => {
    console.log(isSignedIn)
    console.log(loginError)
    console.log(signinClicked)
    if (signinClicked) {
      if (isSignedIn) {
        if (!loginError) {
          history.push('/dashboard');
        }
        else {
          NotificationManager.error(loginError.message, '', 3000)
        }
      }
      else if (loginError) {
        NotificationManager.error(loginError.message, '', 3000)
      }
      
    }
  }, [signinClicked, isFetching, isSignedIn, loginError, history]);

  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <form className="form login-form" onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
          <Alert
            color="danger"
            isOpen={!!errorMessage || !!errorMsg}
          >
            {errorMessage}
            {errorMsg}
          </Alert>
          <div>
            <img alt="logo" src={bhlFeedsLogo} />
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <div class="form__form-group-input-wrap">
                <input
                  name="username"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                />
                {emailError && <span className="form__form-group-error">{emailError}</span>}
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <KeyVariantIcon />
              </div>
              <div class="form__form-group-input-wrap">
                <input
                  name="password"
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={(evt) => setPassword(evt.target.value)}
                />
                {passwordError && <span className="form__form-group-error">{passwordError}</span>}
              </div>
              <div className="account__forgot-password">
                <NavLink to="/reset_password">Forgot a Password?</NavLink>
              </div>
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group form__form-group-field">
            </div>
          </div>
          <div className="account__btns">
            <Button className="account__btn" type="submit" color="primary">Sign In</Button>
          </div>
        </form>
      )}
    </Form>
  )
};

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  form: PropTypes.string.isRequired,
};

LogInForm.defaultProps = {
  errorMessage: '',
  errorMsg: '',
};

export default connect(state => ({
  errorMsg: state.user.error,
}))(LogInForm);
