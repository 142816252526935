import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserSaveCard from './components/UserSaveCard';
import BreadCrumb from '../../shared/components/BreadCrumb';

const ProductSave = () => (
  <Container>
    <Row>
      <Col md={12}>
        <BreadCrumb title="Create User"/>
      </Col>
    </Row>
    <Row>
      <UserSaveCard />
    </Row>
  </Container>
);

export default ProductSave;
