import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import { sendResetPasswordLink } from '../../../redux/actions/authenticationActions';
import { Button } from 'reactstrap';
import { NotificationManager } from "react-notifications";

const ResetPasswordForm = ({
  onSubmit, fieldUser,
}) => {

  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [resetClicked, setResetClicked] = useState(false);


  const isFetching = useSelector((state) => state.authentication.isFetching);
  const resendPasswordError = useSelector(state => state.authentication.error);
  const isResetPasswordSend = useSelector(state => state.authentication.isResetPasswordSend);
  const resetPasswordData = useSelector((state) => state.authentication.data);

  const history = useHistory();

  const handleSubmit = () => {
    setEmailError('');
    let isFormValid = true;
    if (!email) {
      isFormValid = false;
      setEmailError("Email required")
    }
    else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      isFormValid = false;
      setEmailError("Enter valid email")
    }
    if(isFormValid) {
      setResetClicked(true)
      dispatch(sendResetPasswordLink(email));
    }
  }

  useEffect(() => {
    // alert(loginError)
    if(resetClicked){
      if(!isFetching){
        if (resendPasswordError) {
          console.log(resendPasswordError)
          NotificationManager.error(resendPasswordError.message, '', 3000);
          //history.push('/log_in')
        }
        else {
          NotificationManager.success(resetPasswordData.message, "", 3000)
          history.push('/log_in')
        }
      }
    }
  }, [resetPasswordData, resendPasswordError]);


  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <form className="form reset-password-form" onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}>
          <div className="form__form-group">
            <div>
              <span className="form__form-group-label">{fieldUser}</span>
            </div>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AlternateEmailIcon />
              </div>
              <div class="form__form-group-input-wrap">
                <input
                  name="username"
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                />
                {emailError && <span className="form__form-group-error">{emailError}</span>}
              </div>
            </div>
          </div>
          <Button className="account__btn" color="primary">
            Reset Password
          </Button>
        </form>
      )}
    </Form>
  );
}

ResetPasswordForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  fieldUser: PropTypes.shape(),
};

ResetPasswordForm.defaultProps = {
  fieldUser: null,
};

export default ResetPasswordForm;
