import { get } from './base/index';
import { post } from './base/index';

export default {
  getAllProducts: async (param) =>
    await get(`product/all${param}`),
  deleteProducts: async (param) =>
    await post(`product/${param}`),
    uploadImage: async (companyImage) =>
    await post('product/saveProductWithImage',
      {
        companyImage
      }),
  saveProduct: async (productId, productName, description, varientTypes, productSku, image) =>
    await post('product/add',
      { productId: productId, productName: productName, description: description, varientTypes: varientTypes, productSku:productSku, image: image }),
  getSingleProduct: async (id) => 
    await get(`product/${id}`),
  getSingleProductwithVarient: async (id) => 
    await get(`product/varient/${id}`),
  removeImage: async (id) => 
    await post(`product/remove/${id}`)
};
