import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import { NotificationManager } from 'react-notifications';
import { deleteUserMailOTP } from '../../../../redux/actions/deleteAccountAction';
const bhlFeedsLogo = `${process.env.PUBLIC_URL}/img/bhl_logo.JPG`;

const DeleteUserForm = (props) => {
    const setOtpTrigger = props.setOtpTrigger
    const dispatch = useDispatch()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const error = useSelector(state => state.deleteAccount.error);
    const data = useSelector(state => state.deleteAccount.data);
    const isOTPSend = useSelector(state => state.deleteAccount.isOTPSend);

    const handleSubmit = () => {
        setEmailError('');
        setPasswordError('');
        let isFormValid = true;
        if (!email) {
            isFormValid = false;
            setEmailError("Email required")
        }
        else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            isFormValid = false;
            setEmailError("Enter valid email")
        }
        if (!password) {
            isFormValid = false;
            setPasswordError("Password required")
        }
        if (isFormValid) {
            setIsSubmitClicked(true)
            dispatch(deleteUserMailOTP(email, password));
        }
    }

    useEffect(() => {
        if (isSubmitClicked) {
            if (error && error.message) {
                NotificationManager.error(error.message, '', 3000)
            }
            if (data && data.errorCode === 0 && isOTPSend && data.message) {
                setOtpTrigger(true)
                setEmail('');
                setPassword('');
                localStorage.setItem("token", data.token)
                NotificationManager.success(data.message)
            }
        }
    }, [isSubmitClicked, data, error, isOTPSend]);

    return (
        <form className="form login-form" onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
        }}>
            <div className='bhl__feed__logo'>
                <img alt="logo" src={bhlFeedsLogo} />
            </div>
            <div className='delete__form-text'>
                <p className='font-weight-bold'>Delete Account</p>
            </div>
            <div className="form__form-group">
                <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                        <AccountOutlineIcon />
                    </div>
                    <div className="form__form-group-input-wrap">
                        <input
                            name="username"
                            type="text"
                            placeholder="Email"
                            value={email}
                            onChange={(evt) => setEmail(evt.target.value)}
                        />
                        {emailError && <span className="form__form-group-error">{emailError}</span>}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                        <KeyVariantIcon />
                    </div>
                    <div className="form__form-group-input-wrap">
                        <input
                            name="password"
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(evt) => setPassword(evt.target.value)}
                        />
                        {passwordError && <span className="form__form-group-error">{passwordError}</span>}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <div className="form__form-group form__form-group-field">
                </div>
            </div>
            <div className="account__btns">
                <Button className="account__btn" type="submit" color="primary">Submit</Button>
            </div>
        </form>
    )
}

export default DeleteUserForm 