import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import Modal from '../../../../shared/components/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { deleteUserAccount } from '../../../../redux/actions/deleteAccountAction';
const bhlFeedsLogo = `${process.env.PUBLIC_URL}/img/bhl_logo.JPG`;

const DeleteConfirmForm = (props) => {
    const setOtpTrigger = props.setOtpTrigger;
    const setOtpVerified = props.setOtpVerified
    const dispatch = useDispatch() 
    const [deleteModal, setDeleteModal] = useState(false)
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const deleteError = useSelector(state => state.deleteAccount.deleteError);
    const isDeleted = useSelector(state => state.deleteAccount.isDeleted);
    const deleteData = useSelector(state => state.deleteAccount.deleteData);

    const handleCancelDelete = () => {
        setOtpTrigger(false)
        setOtpVerified(false)
        localStorage.removeItem("token")
    }

    const handleDeleteAccount = () => {
        setIsSubmitClicked(true)
        const userToken = localStorage.getItem("token")
        dispatch(deleteUserAccount(userToken))
        setDeleteModal(false)
    }

    useEffect(() => {
        if (isSubmitClicked) {
            if (deleteError && deleteError.message) {
                NotificationManager.error(deleteError.message, '', 3000)
            }
            if (deleteData && deleteData.errorCode === 0 && isDeleted && deleteData.message) {
                setOtpTrigger(false)
                setOtpVerified(false)
                localStorage.removeItem("token")
                NotificationManager.error(deleteData.message)
            }
        }
    }, [isSubmitClicked, deleteData, deleteError, isDeleted]);

    return (
        <div>
            {deleteModal &&
                <Modal
                    isOpen={deleteModal}
                    color="default"
                    title="Warning !"
                    btn="default"
                    message={`Confirm deletion? This action is irreversible.`}
                    onModalSubmit={() => {
                        handleDeleteAccount();
                    }}
                    onHide={() => {
                        setDeleteModal(false);
                    }}
                />
            }
            <div className='bhl__feed__logo'>
                <img alt="logo" src={bhlFeedsLogo} />
            </div>
            <div className='delete__form-text '>
                <p className='font-weight-bold'>Account Deletion</p>
            </div>
            <div className="delete__form-text mt-2">
                <Button onClick={handleCancelDelete}>Cancel</Button>
                <Button color="danger" onClick={() => { setDeleteModal(true) }}>Delete</Button>
            </div>
        </div>
    )
}
export default DeleteConfirmForm