import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  PieChart, Pie, Tooltip, Legend, ResponsiveContainer,
} from 'recharts';
import Panel from '../../../shared/components/Panel';
import getTooltipStyles from '../../../shared/helpers';

const data01 = [{
  id: 0, name: 'Processed', value: 14315, fill: '#4ce1b6',
}, {
  id: 1, name: 'Pending', value: 1032, fill: '#f6da6e',
}, {
  id: 2, name: 'Cancelled', value: 1005, fill: '#ff4861',
}];

const style = (dir) => {
  const left = dir === 'ltr' ? { left: 0 } : { right: 0 };
  return ({
    ...left,
    width: 150,
    lineHeight: '24px',
    position: 'absolute',
  });
};

const renderLegend = ({ payload }) => (
  <ul className="dashboard__chart-legend">
    {payload.map(entry => (
      <li key={entry.payload.id}><span style={{ backgroundColor: entry.color }} />{entry.value}</li>
    ))}
  </ul>
);

renderLegend.propTypes = {
  payload: PropTypes.arrayOf(PropTypes.shape({
    color: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
};

const OrdersPieChart = ({ dir, themeName, pieChartInfo }) => {
  const { t } = useTranslation('common');
  const [coordinate, setCoordinates] = useState({ x: 0, y: 0 });

  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({ x: dir === 'ltr' ? e.tooltipPosition.x : e.tooltipPosition.x / 10, y: e.tooltipPosition.y });
    }
  };

  return (
    <Panel
      lg={6}
      xl={4}
      xs={12}
      title="Orders"
      subhead="Orders Statistics"
    >
      <div dir={dir}>
        <ResponsiveContainer className="dashboard__chart-pie dashboard__chart-pie--crypto" height={300}>
          <PieChart className="dashboard__chart-pie-container">
            <Tooltip
              formatter={value => value.value}
              position={coordinate}
              {...getTooltipStyles(themeName)}
            />
            <Pie
              data={pieChartInfo}
              dataKey="value"
              cy={100}
              innerRadius={30}
              outerRadius={70}
              label={value => value.value}
              onMouseMove={onMouseMove}
            />
            <Legend layout="vertical" verticalAlign="bottom" wrapperStyle={style(dir)} content={renderLegend} />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </Panel>
  );
};

OrdersPieChart.propTypes = {
  dir: PropTypes.string.isRequired,
  themeName: PropTypes.string.isRequired,
};

export default connect(state => ({ themeName: state.theme.className }))(OrdersPieChart);
