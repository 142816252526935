import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, ButtonToolbar, Modal } from 'reactstrap';
import classNames from 'classnames';
const ModalComponent = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(prevState => !prevState);
  };

  let Icon;

  switch (props.color) {
    case 'primary':
      Icon = <span className="lnr lnr-pushpin modal__title-icon" />;
      break;
    case 'success':
      Icon = <span className="lnr lnr-thumbs-up modal__title-icon" />;
      break;
    case 'warning':
      Icon = <span className="lnr lnr-flag modal__title-icon" />;
      break;
    case 'danger':
      Icon = <span className="lnr lnr-cross-circle modal__title-icon" />;
      break;
    default:
      break;
  }
  const modalClass = classNames({
    'modal-dialog--colored': false,
    'modal-dialog--header': '',
  });

  return (
    <div>
      <Modal
        isOpen={true}
        toggle={toggle}
        modalClassName={`ltr-support`}
        className={`modal-dialog--${props.color} ${modalClass}`}
      >
        <div className="modal__header">
          <button
            className="lnr lnr-cross modal__close-btn"
            aria-label="modal__close-btn"
            type="button"
            onClick={props.onHide}
          />
          {/* {header ? '' : Icon} */}
          <h4 className="text-modal  modal__title">{props.title}</h4>
        </div>
        <div className="modal__body">
         <table>
          <tr>
            Mega Calf
          </tr>
          <tr>
            Mega Calf Description
          </tr>
          
          <tr>
            M25kg, 1mt
          </tr>
          </table>
        </div>
        <ButtonToolbar className="modal__footer">
          <Button className="modal_cancel" onClick={props.onHide}>No</Button>{' '}
        </ButtonToolbar>
      </Modal>
    </div>
  );
};

export default connect()(ModalComponent);
