import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import OrdersTable from './components/OrdersTable';
import BreadCrumb from '../../shared/components/BreadCrumb';

const MaterialTable = () => (
  <Container>
    <Row>
      <Col md={12}>
        <BreadCrumb title="Orders"/>
      </Col>
    </Row>
    <Row>
      <OrdersTable />
    </Row>
  </Container>
);

export default MaterialTable;
