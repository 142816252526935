import { handleActions } from 'redux-actions';
import {
  loginRequest, loginSuccess, loginFailed,
  sendResetLinkRequest, sendResetLinkSuccess, sendResetLinkFailed,
  updatePasswordRequest, updatePasswordSuccess, updatePasswordFailed,
} from '../actions/authenticationActions';

const defaultState = {
  data: {},
  isSignedIn: false,
  isFetching: false,
  isResetPasswordSend: false,
  isPasswordUpdated: false,
  error: null,
};

export default handleActions(
  {
    [loginRequest](state) {
      return {
        ...state,
        isFetching: true,
        isSignedIn: false,
        error: null,
      };
    },
    [loginSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isSignedIn: true,
        error: null,
      };
    },
    [loginFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isSignedIn: false,
        error: payload,
      };
    },
    [sendResetLinkRequest](state) {
      return {
        ...state,
        isFetching: true,
        isResetPasswordSend: false,
        error: null,
      };
    },
    [sendResetLinkSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isResetPasswordSend: true,
        error: null,
      };
    },
    [sendResetLinkFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isResetPasswordSend: false,
        error: payload,
      };
    },
    [updatePasswordRequest](state) {
      return {
        ...state,
        isFetching: true,
        isPasswordUpdated: false,
        error: null,
      };
    },
    [updatePasswordSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isPasswordUpdated: true,
        error: null,
      };
    },
    [updatePasswordFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isPasswordUpdated: false,
        error: payload,
      };
    },
  },
  defaultState,
);
