import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { RTLProps } from "../../../shared/prop-types/ReducerProps";

const rows = [
  {
    id: "orderNumber",
    disablePadding: true,
    label: "Order Number",
  },
  {
    id: "name",
    disablePadding: false,
    label: "Customer",
  },
  {
    id: "createdAt",
    disablePadding: false,
    label: "Date",
  },
  {
    id: "orderType",
    disablePadding: false,
    label: "Order Type",
  },
  {
    id: "orderNote",
    disablePadding: false,
    label: "Order Note",
  },
  {
    id: "deliveryType",
    disablePadding: false,
    label: "Delivery Type",
  },
  {
    id: "requestedDeliveryDate",
    disablePadding: false,
    label: "Requested Delivery Date",
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
  },
];

const createSortHandler = (property, onRequestSort) => (event) => {
  onRequestSort(event, property);
};

const OrderTableHead = ({
  onSelectAllClick,
  order,
  orderBy,
  numSelected,
  rowCount,
  rtl,
  onRequestSort,
}) => (
  <TableHead>
    <TableRow>
      {rows.map((row) =>
        row.id === "name" ? (
          <TableCell
            className="material-table__cell material-table__cell--sort material-table__cell-right"
            key={row.id}
            align={rtl.direction === "rtl" ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection
          >
            {row.label}
            {/* <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick
              className="material-table__sort-label"
              dir="ltr"
            >
              {row.label}
            </TableSortLabel> */}
          </TableCell>
        ) : (
          <TableCell
            className="material-table__cell material-table__cell--sort material-table__cell-right"
            key={row.id}
            align={rtl.direction === "rtl" ? "right" : "left"}
            padding={row.disablePadding ? "none" : "default"}
            sortDirection={orderBy === row.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === row.id}
              direction={order}
              onClick={createSortHandler(row.id, onRequestSort)}
              className="material-table__sort-label"
              dir="ltr"
            >
              {row.label}
            </TableSortLabel>
          </TableCell>
        )
      )}
      <TableCell></TableCell>
      {/* <TableCell></TableCell> */}
    </TableRow>
  </TableHead>
);

OrderTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  rtl: RTLProps.isRequired,
};

export default connect((state) => ({
  rtl: state.rtl,
}))(OrderTableHead);
