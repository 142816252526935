import React, { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import Dropzone, { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import { renderComponentField } from '../../../shared/components/form/FormField';
require('dotenv').config();

const DropZoneField = (props) => {
  const files = props.value;
  const onDrop = (file) => {
    props.onChange(file.map(fl => Object.assign(fl, {
      preview: URL.createObjectURL(fl),
    })));
  };
  const removeFile = (index, e) => {
    e.preventDefault();
    props.onChange(props.value.filter((val, i) => i !== index));
  };


  // const [files, setFiles] = useState([]);
  const [error, setError] = useState('')
  const dispatch = useDispatch()

  const {
    getRootProps,
    getInputProps
  } = useDropzone({
    onDrop,
    accept: process.env.REACT_APP_IMAGE_TYPES_SUPPORTED,
    multiple: false,
    maxSize: process.env.REACT_APP_IMAGE_MAX_SIZE
  });


  useEffect(() => {
    if (files.length == 1) {
      props.setPicture(files[0])
    }
  }, [files])

  return (
    <div className={`dropzone dropzone--single${props.customHeight ? ' dropzone--custom-height' : ''}`}>
      <Dropzone
        accept="image/jpeg, image/png"
        name={props.name}
        multiple={false}
        onDrop={(fileToUpload) => {
          onDrop(fileToUpload);
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="dropzone__input">
            {(!files || files.length === 0)
              && (
                <div className="dropzone__drop-here">
                  <span className="lnr lnr-upload" /> Drop file here to upload
                </div>
              )}
            <input {...getInputProps()} />
          </div>
        )}
      </Dropzone>
      {files && Array.isArray(files) && files.length > 0
        && (
          <aside className="dropzone__img">
            <img src={files[0].preview} alt="drop-img" />
            <p className="dropzone__img-name">{files[0].name}</p>
            <button className="dropzone__img-delete" type="button" onClick={e => removeFile(0, e)}>
              Remove
            </button>
          </aside>
        )}
    </div>
  );
};

// DropZoneField.propTypes = {
//   name: PropTypes.string.isRequired,
//   onChange: PropTypes.func.isRequired,
//   customHeight: PropTypes.bool,
//   value: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.arrayOf(PropTypes.shape({
//       name: PropTypes.string,
//     })),
//   ]).isRequired,
// };

// DropZoneField.defaultProps = {
//   customHeight: false,
// };

export default renderComponentField(DropZoneField);
