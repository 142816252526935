import React from 'react';
import { NavLink } from 'react-router-dom';
import showResults from '../../../utils/showResults';
import ResetPasswordForm from '../../../shared/components/reset_password/ResetPasswordForm';

const bhlFeedsLogo = `${process.env.PUBLIC_URL}/img/bhl_logo.JPG`;

const ResetPassword = props => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div>
          <img alt="logo" src={bhlFeedsLogo} />
        </div>
        <h3 className="account__subhead subhead">Forgot password</h3>

        <ResetPasswordForm
          {...props}
          onSubmit={showResults}
        />
        <div className="account__have-account">
          <p>Remember your password?  <NavLink to="/log_in">Login</NavLink></p>
        </div>
      </div>
    </div>
  </div>
);

export default ResetPassword;
