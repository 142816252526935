import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import {
  themeReducer,
  rtlReducer,
  customizerReducer,
  sidebarReducer,
  authReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  authenticationReducer,
  dashboardReducer,
  userReducer,
  productReducer,
  orderReducer,
  promotionReducer,
  deleteAccountReducer
} from '../../redux/reducers/index';
import appConfigReducer from '../../redux/reducers/appConfigReducer';

const reducer = combineReducers({
  theme: themeReducer,
  rtl: rtlReducer,
  roundBorders: roundBordersReducer,
  blocksShadows: blocksShadowsReducer,
  appConfig: appConfigReducer,
  customizer: customizerReducer,
  sidebar: sidebarReducer,
  user: authReducer,
  authentication: authenticationReducer,
  dashboard: dashboardReducer,
  users: userReducer,
  product: productReducer,
  orders: orderReducer,
  promotion: promotionReducer,
  deleteAccount: deleteAccountReducer
});
const store = createStore(reducer, applyMiddleware(thunk));

export default store;
