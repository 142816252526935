import { handleActions } from 'redux-actions';
import {
  getDataRequest, getDataSuccess, getDataFailed,
  getSingleDataRequest, getSingleDataSuccess, getSingleDataFailed,
  processOrderRequest, processOrderSuccess, processOrderFailed,
  cancelOrderRequest, cancelOrderSuccess, cancelOrderFailed
} from '../actions/orderActions';

const defaultState = {
  data: {},
  singleData: {},
  isFetching: false,
  isOrdersDataFetched: false,
  isSingleDataFetched: false,
  isOrderProcess: false,
  isOrderCancel: false,
  error: null,
};

export default handleActions(
  {
    [getDataRequest](state) {
      return {
        ...state,
        isFetching: true,
        isOrdersDataFetched: false,
        error: null,
      };
    },
    [getDataSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isOrdersDataFetched: true,
        error: null,
      };
    },
    [getDataFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isOrdersDataFetched: false,
        error: payload,
      };
    },
    [getSingleDataRequest](state) {
      return {
        ...state,
        isFetching: true,
        isSingleDataFetched: false,
        error: null,
      };
    },
    [getSingleDataSuccess](state, { payload }) {
      return {
        ...state,
        singleData: payload,
        isFetching: false,
        isSingleDataFetched: true,
        error: null,
      };
    },
    [getSingleDataFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isSingleDataFetched: false,
        error: payload,
      };
    },
    [processOrderRequest](state) {
      return {
        ...state,
        isFetching: true,
        isOrderProcess: false,
        error: null,
      };
    },
    [processOrderSuccess](state, { payload }) {
      return {
        ...state,
        singleData: payload,
        isFetching: false,
        isOrderProcess: true,
        error: null,
      };
    },
    [processOrderFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isOrderProcess: false,
        error: payload,
      };
    },
    [cancelOrderRequest](state) {
      return {
        ...state,
        isFetching: true,
        isOrderCancel: false,
        error: null,
      };
    },
    [cancelOrderSuccess](state, { payload }) {
      return {
        ...state,
        singleData: payload,
        isFetching: false,
        isOrderCancel: true,
        error: null,
      };
    },
    [cancelOrderFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isOrderCancel: false,
        error: payload,
      };
    },
  },
  defaultState,
);
