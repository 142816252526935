import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserDetails from './components/UserTemplate';
import BreadCrumb from '../../shared/components/BreadCrumb';

const ProductTemplate = () => (
  <Container>
    <Row>
      <Col md={12}>
        <BreadCrumb title="User Details"/>
      </Col>
    </Row>
    <Row>
      <UserDetails />
    </Row>
  </Container>
);

export default ProductTemplate;
