import React from 'react';
import {
  Card, CardBody, Col, Progress,
} from 'reactstrap';
import TrendingUpIcon from 'mdi-react/TrendingUpIcon';

const TotalCustomers = props => {
  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <CardBody className="dashboard__booking-card">
          <div className="dashboard__booking-total-container">
            <h5 className="dashboard__booking-total-title dashboard__booking-total-title--blue">
              {props.count}
            </h5>
          </div>
          <h5 className="dashboard__booking-total-description">Total customers</h5>
        </CardBody>
      </Card>
    </Col>
  );
}

export default TotalCustomers;
