import { createAction } from 'redux-actions';
import deleteAccountApi from '../../utils/api/deleteAccountApi';

export const deleteUserMailOTPRequest = createAction('DELETE_USER_MAIL_OTP_REQUEST');
export const deleteUserMailOTPSuccess = createAction('DELETE_USER_MAIL_OTP_SUCCESS');
export const deleteUserMailOTPFailed = createAction('DELETE_USER_MAIL_OTP_FAILED');

export const verifyDeleteOtpRequest = createAction('VERIFY_DELETE_OTP_REQUEST');
export const verifyDeleteOtpSuccess = createAction('VERIFY_DELETE_OTP_SUCCESS');
export const verifyDeleteOtpFailed = createAction('VERIFY_DELETE_OTP_FAILED');

export const deleteUserAccountRequest = createAction('DELETE_USER_ACCOUNT_REQUEST');
export const deleteUserAccountSuccess = createAction('DELETE_USER_ACCOUNT_SUCCESS');
export const deleteUserAccountFailed = createAction('DELETE_USER_ACCOUNT_FAILED');

export const deleteUserMailOTP = (email, password) => async (dispatch) => {
  try {
    dispatch(deleteUserMailOTPRequest());
    const results = await deleteAccountApi.deleteUserMailOTP(email, password);
    if (results && results.data && results.data.errorCode === 0) {
      dispatch(deleteUserMailOTPSuccess(results.data));
    }
    else {
      dispatch(deleteUserMailOTPFailed(results.data));
    }
  } catch (e) {
    console.log(e)
    dispatch(deleteUserMailOTPFailed(e));
  }
};

export const verifyDeleteOtp = (otp) => async (dispatch) => {
  try {
    dispatch(verifyDeleteOtpRequest());
    const results = await deleteAccountApi.verifyDeleteOtp(otp);
    if (results && results.data && results.data.errorCode === 0) {
      dispatch(verifyDeleteOtpSuccess(results.data));
    }
    else {
      dispatch(verifyDeleteOtpFailed(results.data));
    }
  } catch (e) {
    console.log(e)
    dispatch(verifyDeleteOtpFailed(e));
  }
};

export const deleteUserAccount = (token) => async (dispatch) => {
  try {
    dispatch(deleteUserAccountRequest());
    const results = await deleteAccountApi.deleteUserAccount(token);
    if (results && results.data && results.data.errorCode === 0) {
      dispatch(deleteUserAccountSuccess(results.data));
    }
    else {
      dispatch(deleteUserAccountFailed(results.data));
    }
  } catch (e) {
    console.log(e)
    dispatch(deleteUserAccountFailed(e));
  }
};