import { get, patch } from './base/index';
import { post } from './base/index';
import { deleteRequest } from './base/index'

export default {
  getAllUsers: async (param) =>
    await get(`users/all${param}`),
  uploadImage: async (companyImage) =>
    await post('users/saveUserWithImage',
      {
        companyImage
      }),
  saveUser: async (email, name, companyName, companyAddress, phoneNo, accountNo, companyImage) =>
    await post('users/create',
      {
        name: name, companyName: companyName, accountNo: accountNo, phoneNo: phoneNo,
        email: email, companyAddress: companyAddress, companyImage: companyImage
      }),
  deleteUser: async (id) =>
    await post(`users/${id}`),
  resendMail: async (id) =>
    await post(`users/resend/${id}`),
  resetPassword: async (id) =>
    await post(`users/resetuserpassword/${id}`),
  editUser: async (id) =>
    await post(`users/update/${id}`),
  getSingleUser: async (id) => 
    await get(`users/${id}`),
  removeImage: async (id) => 
    await post(`users/remove/${id}`)
};

