import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LockClockIcon from 'mdi-react/LockClockIcon';
import { Button } from 'reactstrap';
import { NotificationManager } from 'react-notifications';
import { verifyDeleteOtp } from '../../../../redux/actions/deleteAccountAction';
const bhlFeedsLogo = `${process.env.PUBLIC_URL}/img/bhl_logo.JPG`;

const VerifyOTPForm = (props) => {
    const dispatch = useDispatch()
    const setOtpVerified = props.setOtpVerified
    const [otp, setOTP] = useState('');
    const [otpError, setOTPError] = useState('');
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);

    const verifyOtpError = useSelector(state => state.deleteAccount.verifyOtpError);
    const isOTPVerified = useSelector(state => state.deleteAccount.isOTPVerified);
    const verifyOtpData = useSelector(state => state.deleteAccount.verifyOtpData);

    const handleSubmit = () => {
        setOTPError('');
        let isFormValid = true;
        if (!otp) {
            isFormValid = false;
            setOTPError("OTP required")
        }
        if (isFormValid) {
            setIsSubmitClicked(true)
            dispatch(verifyDeleteOtp(otp));
        }
    }

    useEffect(() => {
        if (isSubmitClicked) {
            if (verifyOtpError && verifyOtpError.message) {
                NotificationManager.error(verifyOtpError.message, '', 3000)
            }
            if (verifyOtpData && verifyOtpData.errorCode === 0 && isOTPVerified && verifyOtpData.message) {
                setOtpVerified(true)
                setOTP('')
                NotificationManager.success(verifyOtpData.message)
            }
        }
    }, [isSubmitClicked, verifyOtpError, isOTPVerified, verifyOtpData]);

    return (
        <form className="form login-form" onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
        }}>
            <div className='bhl__feed__logo'>
                <img alt="logo" src={bhlFeedsLogo} />
            </div>
            <div className='delete__form-text'>
                <p className='font-weight-bold'>Verify Email OTP</p>
            </div>
            <div className="form__form-group">
                <div className="form__form-group-field">
                    <div className="form__form-group-icon">
                        <LockClockIcon />
                    </div>
                    <div className="form__form-group-input-wrap">
                        <input
                            name="otp"
                            type="text"
                            placeholder="OTP"
                            value={otp}
                            onChange={(evt) => setOTP(evt.target.value)}
                        />
                        {otpError && <span className="form__form-group-error">{otpError}</span>}
                    </div>
                </div>
            </div>
            <div className="form__form-group">
                <div className="form__form-group form__form-group-field">
                </div>
            </div>
            <div className="account__btns">
                <Button className="account__btn" type="submit" color="primary">Verify</Button>
            </div>
        </form>
    )
}

export default VerifyOTPForm