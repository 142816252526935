import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import UserEditCard from './components/UserEditCard';
import BreadCrumb from '../../shared/components/BreadCrumb';

const UserEdit = () => (
  <Container>
    <Row>
      <Col md={12}>
        <BreadCrumb title="Edit User"/>
      </Col>
    </Row>
    <Row>
      <UserEditCard />
    </Row>
  </Container>
);

export default UserEdit;
