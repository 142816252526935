import { handleActions } from 'redux-actions';
import {
  getDataRequest, getDataSuccess, getDataFailed,
  savePromotionRequest, savePromotionSuccess, savePromotionFailed
} from '../actions/promotionsActions';

const defaultState = {
  data: {},
  isFetching: false,
  isPrommotionsDataFetched: false,
  isPromotionSaved: false,
  error: null,
};

export default handleActions(
  {
    [getDataRequest](state) {
      return {
        ...state,
        isFetching: true,
        isPrommotionsDataFetched: false,
        error: null,
      };
    },
    [getDataSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isPrommotionsDataFetched: true,
        error: null,
      };
    },
    [getDataFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isPrommotionsDataFetched: false,
        error: payload,
      };
    },
    [savePromotionRequest](state) {
      return {
        ...state,
        isFetching: true,
        isPromotionSaved: false,
        error: null,
      };
    },
    [savePromotionSuccess](state, { payload }) {
      return {
        ...state,
        data: payload,
        isFetching: false,
        isPromotionSaved: true,
        error: null,
      };
    },
    [savePromotionFailed](state, { payload }) {
      return {
        ...state,
        isFetching: false,
        isPromotionSaved: false,
        error: payload,
      };
    },
  },
  defaultState,
);
