import themeReducer from './themeReducer';
import rtlReducer from './rtlReducer';
import roundBordersReducer from './roundBordersReducer';
import blocksShadowsReducer from './blocksShadowsReducer';
import sidebarReducer from './sidebarReducer';
import customizerReducer from './customizerReducer';
import authReducer from './authReducer';
import authenticationReducer from './authenticationReducer';
import dashboardReducer from './dashboardReducer';
import userReducer from './userReducer';
import productReducer from './productReducer';
import orderReducer from './orderReducer';
import promotionReducer from './promotionReducer';
import deleteAccountReducer from './deleteAccountReducer'

export {
  themeReducer,
  rtlReducer,
  sidebarReducer,
  customizerReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  authReducer,
  authenticationReducer,
  dashboardReducer,
  userReducer,
  productReducer,
  orderReducer,
  promotionReducer,
  deleteAccountReducer
};
