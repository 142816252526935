import { get } from './base/index';
import { post } from './base/index';
import { patch } from './base/index';

export default {
  getAllOrders: async (param) =>
    await get(`order/list${param}`),
  getSingleOrder: async (id) => 
    await get(`order/${id}`),
  processOrder: async (id) =>
    await post(`order/process/${id}`),
  cancelOrder: async (id) =>
    await post(`order/cancel/${id}`),
};
