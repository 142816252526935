import React, { useState, useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { ButtonToolbar, Badge, Card, CardBody, Col } from "reactstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import EyeOutlineIcon from "mdi-react/EyeOutlineIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import EditIcon from "mdi-react/EditIcon";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import UsersTableHead from "./UsersTableHead";
import { NotificationManager } from "react-notifications";
import { getAllUsers, deleteUser, resendMail, resetUserPassword } from "../../../redux/actions/userActions";
import Modal from '../../../shared/components/Modal';

const UsersTable = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isFetching = useSelector((state) => state.users.isFetching);
  const userError = useSelector((state) => state.users.error);
  const usersData = useSelector((state) => state.users.data);
  const userDelete = useSelector((state) => state.users.isUserDeleted);
  const userResendMail = useSelector((state) => state.users.isResendMail);
  const userResetPassword = useSelector((state) => state.users.isResetUserPassword);
  console.log(usersData)

  const [searchText, setSearchText] = useState("");
  const [sortName, setSortName] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [userId, setUserId] = useState();
  const [selectedUserName, setSelectedUserName] = useState();
  const [showResendConfirmModal, setShowResendConfirmModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);

  const [deleteConfirmClicked, setDeleteConfirmClicked] = useState(false);
  const [resendInviteConfirmClicked, setResendInviteConfirmClicked] = useState(false);
  const [resetPasswordConfirmClicked, setResetPasswordConfirmClicked] = useState(false);

  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem("adminInfo"));
    if (adminObj) {
      dispatch(getAllUsers(searchText, sortName, sortOrder, page, rowsPerPage));
    } else {
      history.push("/log_in");
    }
  }, []);

  useEffect(() => {
    console.log(resetPasswordConfirmClicked)
    if (!isFetching) {
      if (deleteConfirmClicked) {
        if (userError) {
          NotificationManager.error(userError.message, "", 3000);
        }
        else {
          NotificationManager.success("User deleted.", "", 3000);
        }
        setDeleteConfirmClicked(false);
        dispatch(getAllUsers(searchText, sortName, sortOrder, page, rowsPerPage));
        window.location.reload(false);
      }
      else if (resendInviteConfirmClicked) {
        if (userError) {
          NotificationManager.error(userError.message, "", 3000);
        }
        else {
          NotificationManager.success("Email invitation has been sent.", "", 3000);
        }
        setResendInviteConfirmClicked(false);
        dispatch(getAllUsers(searchText, sortName, sortOrder, page, rowsPerPage));
        // window.location.reload(false);
      }
      else if (resetPasswordConfirmClicked) {
        console.log(resetPasswordConfirmClicked)
        console.log(userError)
        if (userError) {
          NotificationManager.error(userError.message, "", 3000);
        }
        else {
          NotificationManager.success("Password has been reset for the user and email send.", "", 3000);
        }
        setResetPasswordConfirmClicked(false);
        dispatch(getAllUsers(searchText, sortName, sortOrder, page, rowsPerPage));
        // window.location.reload(false);
      }
      else if (
        usersData &&
        usersData.users &&
        usersData.users.length > 0
      ) {
        setData(usersData.users);
      }
    }
  }, [isFetching, usersData, userError, deleteConfirmClicked, resendInviteConfirmClicked, resetPasswordConfirmClicked]);

  useEffect(() => {
    dispatch(getAllUsers(searchText, sortName, sortOrder, page, rowsPerPage));
  }, [searchText, sortName, sortOrder, page, rowsPerPage]);

  const handleSearch = () => {
    // dispatch(getAllUsers(searchText, sortName, sortOrder, page, rowsPerPage));
  };

  const handleRequestSort = (event, property) => {
    let orderTemp = "desc";
    if (sortName === property && sortOrder === "desc") {
      orderTemp = "asc";
    }
    setSortName(property);
    setSortOrder(orderTemp);
  };

  const handleChangePage = (event, item) => {
    setPage(item);
    console.log(item);
    // dispatch(getAllUsers(searchText, sortName, sortOrder, item, rowsPerPage));
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
    // dispatch(getAllUsers(searchText, sortName, sortOrder, page, rowsPerPage));
  };

  const handleEditClick = (userId) => {
    console.log(userId)
    setUserId(userId)
    history.push({ pathname: '/editUser', state: { userId: userId } });
  };

  const handleClose = () => {
    setShowResendConfirmModal(false)
    setShowResetPasswordModal(false)
    setDeleteUserModal(false)
  }

  const handleDeleteSelected = (userId, userName) => {
    setUserId(userId)
    setSelectedUserName(userName)
    setDeleteUserModal(true)
  };

  const sendDeleteUser = () => {
    setDeleteConfirmClicked(true)
    setDeleteUserModal(true)
    dispatch(deleteUser(userId));
  }

  const handleResendMail = (userId, userName) => {
    setUserId(userId)
    setSelectedUserName(userName)
    setShowResendConfirmModal(true)
  };

  const sendConfirmationMail = () => {
    setResendInviteConfirmClicked(true)
    setShowResendConfirmModal(false);
    dispatch(resendMail(userId));
  }

  const handleResetPassword = (userId, userName) => {
    console.log(userId)
    console.log(userName)
    setUserId(userId)
    setSelectedUserName(userName)
    setShowResetPasswordModal(true)
  };

  const resetPassword = () => {
    setResetPasswordConfirmClicked(true);
    setShowResetPasswordModal(false);
    dispatch(resetUserPassword(userId));
  }

  const handleOnViewClick = (userId) => {
    setUserId(userId)
    console.log(userId)
    history.push({ pathname: '/viewuser', state: { userId: userId } });
  };

  const isSelected = (id) => !!selected.get(id);
  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <>
      {
        showResendConfirmModal && <Modal
          isOpen={showResendConfirmModal}
          color="default"
          title="Confirmation !"
          btn="default"
          message={`Are you sure to resend welcome mail to ${selectedUserName}`}
          onModalSubmit={() => {
            sendConfirmationMail();
          }}
          onHide={() => {
            handleClose();
          }}
        />
      }

      {
        showResetPasswordModal && <Modal
          isOpen={showResetPasswordModal}
          color="default"
          title="Confirmation !"
          btn="default"
          message={`Are you sure to reset password of ${selectedUserName}`}
          onModalSubmit={() => {
            resetPassword();
          }}
          onHide={() => {
            handleClose();
          }}
        />
      }

      {
        deleteUserModal && <Modal
          isOpen={deleteUserModal}
          color="default"
          title="Warning !"
          btn="default"
          message={`Are you sure to delete ${selectedUserName}`}
          onModalSubmit={() => {
            sendDeleteUser();
          }}
          onHide={() => {
            handleClose();
          }}
        />
      }

      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <div className="inbox__emails-control-search">
                <input
                  placeholder="Search"
                  name="search"
                  type="text"
                  value={searchText}
                  onChange={(evt) => setSearchText(evt.target.value)}
                />
                <div
                  className="inbox__emails-control-search-icon"
                  onClick={handleSearch}
                >
                  <MagnifyIcon />
                </div>
              </div>
              {/* <h5 className="bold-text">Products List</h5> */}
              <ButtonToolbar className="products-list__btn-toolbar-top">
                <Link
                  className="btn btn-primary products-list__btn-add"
                  to="/saveUser"
                >
                  Create User
                </Link>
              </ButtonToolbar>
            </div>
            {usersData.users && usersData.users.length > 0 ? (
              <div>
                <div className="material-table__wrap">
                  <Table className="material-table">
                    <UsersTableHead
                      numSelected={[...selected].filter((el) => el[1]).length}
                      order={sortOrder}
                      orderBy={sortName}
                      onSelectAllClick
                      onRequestSort={handleRequestSort}
                      rowCount={usersData.users.length}
                    />
                    <TableBody>
                      {usersData.users.map((d) => {
                        const select = isSelected(d.id);
                        return (
                          <TableRow
                            className="material-table__row"
                            aria-checked={select}
                            tabIndex={-1}
                            key={d.id}
                            selected={select}
                          >
                            <TableCell
                              className="material-table__cell material-table__cell-right"
                              component="th"
                              scope="row"
                              padding="none"
                            >
                              {d.accountNo}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.name}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.companyName}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.phoneNo}
                            </TableCell>
                            <TableCell className="material-table__cell material-table__cell-right">
                              {d.email}
                            </TableCell>
                            <TableCell style={{ width: '20px' }}>
                              <button
                                onClick={() =>
                                  handleOnViewClick(
                                    d._id,
                                    d.userName
                                  )
                                }
                                className="cart__table-btn"
                                type="button"
                              >
                                <EyeOutlineIcon /> View
                              </button>
                            </TableCell>
                            <TableCell style={{ width: '20px' }}>
                              <button className="cart__table-btn" type="button" onClick={() => handleEditClick(d._id)}>
                                <EditIcon /> Edit
                              </button>
                            </TableCell>
                            <TableCell style={{ width: '20px' }}>
                              <button
                                className="cart__table-btn"
                                type="button"
                                //onClick={handleDeleteSelected(d._id)}
                                onClick={() => handleDeleteSelected(d._id, d.name)}
                              >
                                <DeleteIcon /> Delete
                              </button>
                            </TableCell>
                            <TableCell style={{ width: '20px' }}>
                              <button
                                className="cart__table-btn"
                                type="button"
                                onClick={() => handleResendMail(d._id, d.name)}
                              >
                                Resend Invite
                              </button></TableCell>
                            <TableCell style={{ width: '20px' }}><button
                              className="cart__table-btn"
                              type="button"
                              onClick={() => handleResetPassword(d._id, d.name)}
                            >
                              Reset Password
                            </button></TableCell>
                          </TableRow>
                        );
                      })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 2 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </div>
                <TablePagination
                  component="div"
                  className="material-table__pagination"
                  count={usersData.total[0].total}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                  rowsPerPageOptions={[5, 10, 15]}
                  dir="ltr"
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                />
              </div>
            ) : (
              <div>No Users found</div>
            )}
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default UsersTable;
