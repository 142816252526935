import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { Card, Col, Button, ButtonToolbar, Table, CardBody } from "reactstrap";
import Alert from "../../../shared/components/Alert";
import Modal from "../../../shared/components/Modal";
import { singleProduct } from "../../../redux/actions/productActions";

const ProductTemplate = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const isFetching = useSelector((state) => state.product.isFetching);
  const singleProductDetails = useSelector((state) => state.product.singleData);

  const [productDetails, setProductDetails] = useState({});

  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem("adminInfo"));
    if (adminObj) {
      dispatch(singleProduct(location.state.productId));
    } else {
      history.push("/log_in");
    }
  }, []);

  useEffect(() => {
    console.log(isFetching)
    if (!isFetching) {
      console.log(singleProductDetails)
      if (singleProductDetails && singleProductDetails.product && singleProductDetails.product.length > 0)
        setProductDetails(singleProductDetails)
    }
  }, [isFetching, singleProductDetails]);

  const backToProducts = () => {
    history.push("/products");
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          {singleProductDetails.product && singleProductDetails.product.length > 0 && singleProductDetails.product?.map((d) => {
            let productImage = d.productImage ? `${d.productImage.toString('base64')}` : '';
            return <div className="product-card">
              <div className="product-gallery">
                <button type="button" className="product-gallery__current-img">
                  <img src={productImage} alt="product-img" />
                </button>
              </div>
              <div className="product-card__info">
                <h3 className="product-card__title">{d.productDisplatName}</h3>
                <h5 className="product-card__price">{d.skuNumber}</h5>
                <p className="typography-message">{d.description}</p>
              </div>
            </div>
          })}
          <div className="invoice__total">
            <Button onClick={backToProducts}>Back to Products</Button>
          </div>
        </CardBody>
      </Card>
    </Col >
  );
};

export default ProductTemplate;
