import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';

const SidebarContent = ({ onClick }) => (
  <div className="sidebar__content">
    <ul className="sidebar__block">
      <SidebarLink
        title="Dashboard"
        icon="store"
        route="/dashboard"
        onClick={onClick}
      />
      <SidebarLink
        title="Orders"
        icon="database"
        route="/orders"
        onClick={onClick}
      />
      <SidebarLink
        title="Products"
        icon="tag"
        route="/products"
        onClick={onClick}
      />
      <SidebarLink
        title="Users"
        icon="users"
        route="/users"
        onClick={onClick}
      />
      <SidebarLink
        title="Promotions"
        icon="bullhorn"
        route="/promotions"
        onClick={onClick}
      />
    </ul>
  </div>
);

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
  onClick: () => { },
};

export default SidebarContent;
