import { createAction } from 'redux-actions';
import ordersApi from '../../utils/api/ordersApi';

export const getDataRequest = createAction('FETCH_ORDERS_REQUEST');
export const getDataSuccess = createAction('FETCH_ORDERS_SUCCESS');
export const getDataFailed = createAction('FETCH_ORDERS_FAILED');

export const getSingleDataRequest = createAction('FETCH_SINGLE_ORDER_REQUEST');
export const getSingleDataSuccess = createAction('FETCH_SINGLE_ORDER_SUCCESS')
export const getSingleDataFailed = createAction('FETCH_SINGLE_ORDER_FAILED')

export const processOrderRequest = createAction('FETCH_PROCESS_ORDER_REQUEST');
export const processOrderSuccess = createAction('FETCH_PROCESS_ORDER_SUCCESS');
export const processOrderFailed = createAction('FETCH_PROCESS_ORDER_FAILED')

export const cancelOrderRequest = createAction('FETCH_CANCEL_ORDER_REQUEST');
export const cancelOrderSuccess = createAction('FETCH_CANCEL_ORDER_SUCCESS');
export const cancelOrderFailed = createAction('FETCH_CANCEL_ORDER_FAILED')

export const getAllOrders = (searchText, sortName, sortOrder, currentPage, pageLimit) => async (dispatch) => {
  searchText = searchText ? searchText : '';
  try {
    let param = '?sortOrder=' + sortOrder +
      "&sortName=" + sortName +
      "&currentPage=" + currentPage +
      "&pageLimit=" + pageLimit +
      "&keyword=" + searchText;
    dispatch(getDataRequest());
    const results = await ordersApi.getAllOrders(param);
    console.log(results)
    if (results && results.error) {
      dispatch(getDataFailed(results.error));
    }
    else if (results.data && results.data.orders) {
      dispatch(getDataSuccess(results.data));
    }
    else {
      dispatch(getDataFailed(results));
    }
  } catch (e) {
    console.log(e)
    dispatch(getDataFailed(e));
  }
};

export const singleOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(getSingleDataRequest());
    const results = await ordersApi.getSingleOrder(orderId);
    console.log(results.data)
    if (results && results.error) {
      dispatch(getSingleDataFailed(results.error));
    }
    else if (results && results.data) {
      dispatch(getSingleDataSuccess(results.data));
    }
    else {
      dispatch(getSingleDataFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(getSingleDataFailed(e));
  }
};

export const orderProcess = (orderId) => async (dispatch) => {
  try {
    dispatch(processOrderRequest());
    const results = await ordersApi.processOrder(orderId);
    console.log(results)
    if (results && results.errorCode > 0) {
      dispatch(processOrderFailed(results));
    }
    else if (results) {
      dispatch(processOrderSuccess(results));
    }
    else {
      console.log(results.error)
      dispatch(processOrderFailed(results));
    }
  } catch (e) {
    console.log(e)
    dispatch(processOrderFailed(e));
  }
};

export const cancelOrder = (orderId) => async (dispatch) => {
  try {
    dispatch(cancelOrderRequest());
    const results = await ordersApi.cancelOrder(orderId);
    console.log(results)
    if (results && results.error) {
      dispatch(cancelOrderFailed(results.error));
    }
    else if (results) {
      dispatch(cancelOrderSuccess(results));
    }
    else {
      dispatch(cancelOrderFailed(results.error));
    }
  } catch (e) {
    console.log(e)
    dispatch(cancelOrderFailed(e));
  }
};
