import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';
import {
  Button, ButtonToolbar, Col, Container, Row, Alert
}
  from 'reactstrap';
import { Field, Form } from 'react-final-form';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import TagIcon from 'mdi-react/TagIcon';
import renderDropZoneMultipleField from '../../../shared/components/form/DropZoneMultiple';
import renderSelectField from '../../../shared/components/form/Select';
import BasicTable from './BasicTable';
import { NotificationManager } from "react-notifications";
import {savePromotion} from '../../../redux/actions/promotionsActions'

const PromotionSendForm = ({ onSubmit , errorMessage, errorMsg}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [message, setMessage] = useState("");
  const [title, setTitle] = useState("");
  const [messageError, setMessageError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [promotionSaveClicked, setPromotionSaveClicked] = useState(false);

  const isFetching = useSelector((state) => state.promotion.isFetching);
  const isPromotionSaved = useSelector((state) => state.promotion.isPromotionSaved);
  const promotionData = useSelector((state) => state.promotion.data);
  const promotionError = useSelector((state) => state.promotion.error);
  console.log(isFetching)

  const handleSubmit = (e) => {
    e.preventDefault();
    setMessageError("");
    setTitleError("");
    let isFormValid = true;
    if (!message) {
      isFormValid = false;
      setMessageError("Message required");
    }
    if (!title) {
      isFormValid = false;
      setTitleError("Title required");
    }
    if (isFormValid) {
      setPromotionSaveClicked(true);
      dispatch(
        savePromotion(title,message)
      );
    }
  };

  const backToPromotions = () => {
    history.push('/promotions');
  };

  useEffect(() => {
    let adminObj = JSON.parse(localStorage.getItem('adminInfo'));
    if (adminObj) {
      dispatch(savePromotion());
    }
    else {
      history.push("/log_in")
    }

  }, [dispatch, history]);
  useEffect(() => {
    if (promotionSaveClicked) {
      if (!isFetching) {
        if (promotionError) {
          console.log(promotionError)
          NotificationManager.error(promotionError.message, "", 3000);
        } else {
          NotificationManager.success(promotionData.message, "", 3000);
          history.push("/promotions");
        }
      } 
    }
  }, [history, isFetching, promotionError, promotionSaveClicked, isPromotionSaved, promotionData]);

 

  return (
    <Form onSubmit={onSubmit}>
      {() => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <Alert color="danger" isOpen={!!errorMessage || !!errorMsg}>
            {errorMessage}
            {errorMsg}
          </Alert>
          <Container>
            <Row>
              <Col md={12} lg={12} xl={7}>
                <div className="form__half">
                  <div className="form__form-group">
                    <span className="form__form-group-label">Title</span>
                    <div className="form__form-group-input-wrap">
                    <input
                        name="name"
                        component="input"
                        type="text"
                        value={title}
                        onChange={(evt) => setTitle(evt.target.value)}
                      />
                      {titleError && (
                        <span className="form__form-group-error">
                          {titleError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="form__form-group">
                    <span className="form__form-group-label">Message</span>
                    <div className="form__form-group-input-wrap">
                    <input
                        name="email"
                        component="input"
                        type="text"
                        value={message}
                        onChange={(evt) => setMessage(evt.target.value)}
                      />
                      {messageError && (
                        <span className="form__form-group-error">
                          {messageError}
                        </span>
                      )}
                    </div>
                  </div>
                  <div>
                    <ButtonToolbar className="form__button-toolbar">
                      <Button color="primary" onClick={handleSubmit}>Send</Button>
                      <Button type="button" onClick={backToPromotions}>Cancel</Button>
                    </ButtonToolbar>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      )}
    </Form>
  )
};

PromotionSendForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default PromotionSendForm;
