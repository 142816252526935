import { createAction } from 'redux-actions';
import authenticationApi from '../../utils/api/authenticationApi';

export const loginRequest = createAction('LOGIN_REQUEST');
export const loginSuccess = createAction('LOGIN_SUCCESS');
export const loginFailed = createAction('LOGIN_FAILED');

export const sendResetLinkRequest = createAction('SEND_RESET_REQUEST');
export const sendResetLinkSuccess = createAction('SEND_RESET_SUCCESS');
export const sendResetLinkFailed = createAction('SEND_RESET_FAILED');

export const updatePasswordRequest = createAction('UPDATE_PPASSWORD_REQUEST');
export const updatePasswordSuccess = createAction('UPDATE_PPASSWORD_SUCCESS');
export const updatePasswordFailed = createAction('UPDATE_PPASSWORD_FAILED');

export const FETCHAUTH_SUCCESS = 'FETCHAUTH_SUCCESS';

export const adminLogin = (email, password) => async (dispatch) => {
  try {
    dispatch(loginRequest());
    const results = await authenticationApi.adminLogin(email, password);
    console.log(results)
    if (results && results.data && results.data.errorCode === 0) {
      localStorage.setItem('adminInfo', JSON.stringify(results.data.result));
      dispatch(loginSuccess(results));
    }
    else {
      dispatch(loginFailed(results.data));
    }
  } catch (e) {
    console.log(e)
    dispatch(loginFailed(e));
  }
};

export const sendResetPasswordLink = (email) => async (dispatch) => {
  try {
    dispatch(sendResetLinkRequest());
    const results = await authenticationApi.sendResetPasswordLink(email);
    console.log(results)
    if (results && results.data && results.data.errorCode !== 0) {
      dispatch(sendResetLinkFailed(results.data));
    }else if (results && results.data ) {
      dispatch(sendResetLinkSuccess(results.data));
    }
    else {
      dispatch(sendResetLinkFailed(results.data));
    }
  } catch (e) {
    console.log(e)
    dispatch(sendResetLinkFailed(e));
  }
};

export const updatePassword = (email, password) => async (dispatch) => {
  try {
    dispatch(updatePasswordRequest());
    const results = await authenticationApi.updatePassword(email, password);
    console.log(results)
    if (results && results.error) {
      dispatch(updatePasswordFailed(results.error));
    }
    else {
      dispatch(updatePasswordSuccess(results));
    }
  } catch (e) {
    console.log(e)
    dispatch(updatePasswordFailed(e));
  }
};